.form-module__form___OLneK {
  box-shadow: 0 7px 12px rgba(33, 34, 34, 0.04);
  background-color: #ffffff;
  position: absolute;
  z-index: 999;
  left: 50%;
  top: 40px;
  transform: translateX(-50%);
  padding: 0px 20px 20px 20px;
  min-width: 405px;
  border: 1px solid #0076ff;
  -o-border-image: linear-gradient(#0076ff, #fff) 27/1px;
     border-image: linear-gradient(#0076ff, #fff) 27/1px; }

.form-module__form-background___NhD2J {
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  background: #0076ff;
  background: linear-gradient(to bottom, #07f 0%, #fff 100%); }

.form-module__input-row___qY1J3 {
  display: -ms-flexbox;
  display: flex;
  margin-top: 20px;
  -ms-flex-align: center;
      align-items: center;
  margin-left: -10px;
  margin-right: -10px; }

.form-module__input-container___3064j {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex: 1;
      flex: 1;
  margin-left: 10px;
  margin-right: 10px; }

.form-module__input-container-small___DQbnk {
  margin-left: 0px;
  margin-right: 0px; }

.form-module__input-row-small___3ICNz > div {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  margin-left: 10px;
  margin-right: 10px; }

.form-module__input-row-small___3ICNz .form-module__input___35gSC {
  margin-top: 3px;
  max-width: 100px; }

.form-module__label-container___2-bJ- {
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 5px; }

.form-module__label___33g2t {
  color: #a7b6c7;
  font-size: 10px;
  font-weight: 700;
  text-transform: uppercase;
  margin-left: 5px; }

.form-module__rowLabel___23sGl {
  margin: 0;
  margin-left: calc(5px + 10px);
  margin-bottom: 2px; }

.form-module__input___35gSC {
  border-radius: 4px;
  border: 1px solid #d3dbe4;
  outline: none;
  height: 40px;
  color: #000000;
  font-size: 14px;
  font-weight: 400;
  padding-left: 10px;
  max-width: 175px;
  background: white; }
  .form-module__input___35gSC:focus {
    border: 1px solid #0076ff; }

.form-module__teaxtarea___1OV30 {
  padding-top: 15px;
  padding-left: 15px;
  max-width: 100%;
  padding-bottom: 15px;
  height: 80px; }

.form-module__splitter___3EBXS {
  padding-left: 8px;
  padding-right: 8px;
  color: #000000;
  font-size: 14px;
  font-weight: 400; }

.form-module__save___CHGae {
  color: #0076ff;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.3px;
  width: 78px;
  height: 35px;
  border-radius: 4px;
  border: 1px solid #0076ff;
  -ms-flex-item-align: end;
      align-self: flex-end;
  margin-top: auto;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  cursor: pointer;
  margin-top: 20px;
  margin-left: auto; }
  .form-module__save___CHGae:hover {
    background: white; }

.form-module__close___1dZKl {
  position: absolute;
  top: 10px;
  right: 10px; }
