.customization-module__container___1GErp {
  height: 100%;
  background: #221b22;
  min-width: 300px;
  -ms-flex: 1;
      flex: 1;
  padding-top: 50px;
  overflow-y: auto;
  transform: translateZ(0px); }

.customization-module__elements___vtC5S {
  padding: 10px; }
  .customization-module__elements___vtC5S > div {
    margin-top: 32px; }

.customization-module__tabs___25Ahy {
  background-color: #171317;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -ms-flex-align: center;
      align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  position: sticky;
  top: 0px;
  z-index: 999; }
  .customization-module__tabs___25Ahy > div {
    -ms-flex: 1;
        flex: 1; }

.customization-module__tab___1GEKy {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  cursor: pointer; }
  .customization-module__tab___1GEKy p {
    position: relative;
    color: #ffffff;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 0.6px;
    line-height: 24px;
    text-transform: uppercase; }
    .customization-module__tab___1GEKy p:hover {
      color: #e10b5d; }

.customization-module__tab-active___3Aq-I p:after {
  position: absolute;
  display: block;
  content: '';
  width: 120%;
  left: -10%;
  height: 4px;
  background: #e10b5d;
  top: 125%;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px; }
