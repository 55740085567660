.input-module__label___3BWWX {
  color: rgba(169, 169, 169, 0.5);
  font-size: 10px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 6px; }

.input-module__input___3oRQz {
  height: 45px;
  width: 100%;
  border: 1px solid #3d343d;
  background-color: #2c242c;
  border-radius: 4px;
  padding-left: 15px;
  font-size: 14px;
  outline: none;
  color: white; }
  .input-module__input___3oRQz:focus {
    border: 1px solid #4b404b; }
