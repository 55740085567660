.lead-module__lead___3ekGo {
  width: 100%;
  padding: 18px;
  background: white;
  border-radius: 4px;
  border: 1px solid #f0f0f0;
  background-color: #ffffff;
  display: -ms-flexbox;
  display: flex;
  height: 76px;
  -ms-flex-align: center;
      align-items: center;
  margin-top: 10px;
  position: relative; }
  .lead-module__lead___3ekGo > div {
    -ms-flex: 1;
        flex: 1; }

.lead-module__lead-blur___2M42i {
  filter: blur(3px);
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  pointer-events: none; }

.lead-module__open-lead___3ACCK {
  width: 100%;
  padding: 18px;
  background: white;
  border-radius: 4px;
  border: 1px solid #f0f0f0;
  background-color: #ffffff;
  margin-top: 10px;
  position: relative; }

.lead-module__example___1vGyb {
  width: 67px;
  height: 18px;
  position: absolute;
  top: 0;
  right: 0;
  text-align: center;
  padding-top: 4px;
  color: #ffab00;
  font-size: 10px;
  font-weight: 700;
  text-transform: uppercase;
  background-color: rgba(255, 171, 0, 0.1); }

.lead-module__flex___1NVtv {
  -ms-flex: 1;
      flex: 1; }

.lead-module__column___27yuF > p:nth-child(2) {
  margin-top: 4px; }

.lead-module__column-small___gKmxg {
  max-width: 10%; }

.lead-module__column-medium___12arQ {
  max-width: 16%; }

.lead-module__column-long___1SgmH {
  max-width: 22%; }

.lead-module__headline___183IM {
  color: #2c242c;
  font-size: 13px;
  font-weight: 700; }

.lead-module__smaller___2j4VP {
  color: #a9a9a9;
  font-size: 12px;
  font-weight: 400; }

.lead-module__more-container___2Tl7u {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: justify;
      justify-content: space-between; }

.lead-module__more___ajBEp {
  width: 102px;
  border-radius: 2px;
  background-color: rgba(46, 49, 53, 0.05);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  padding: 8px 10px;
  margin-left: auto;
  color: #2c242c;
  font-size: 10px;
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }
  .lead-module__more___ajBEp p {
    margin-right: 6px; }
  .lead-module__more___ajBEp:hover {
    color: #0076ff;
    background-color: rgba(46, 49, 53, 0.1); }

.lead-module__more-active___2WXSk {
  background-color: rgba(0, 118, 255, 0.2);
  color: #0076ff; }
  .lead-module__more-active___2WXSk:hover {
    background-color: rgba(0, 118, 255, 0.1); }

.lead-module__msg___3fd9z {
  color: #2c242c;
  font-size: 13px;
  font-weight: 400;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 84%; }

.lead-module__status___g7p4c {
  border-radius: 4px;
  padding: 6px 8px;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
      align-items: center;
  height: 28px; }
  .lead-module__status___g7p4c p {
    font-size: 10px;
    font-weight: 700;
    text-transform: uppercase; }

.lead-module__status-dot___x2WRH {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  margin-right: 6px; }

.lead-module__status-win___1mc4v {
  background-color: rgba(114, 204, 14, 0.1);
  color: #72cc0e; }
  .lead-module__status-win___1mc4v .lead-module__status-dot___x2WRH {
    background-color: #72cc0e; }

.lead-module__open-lead-header___P4kbr {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-align: center;
      align-items: center;
  height: 40px; }

.lead-module__lead-name___3iaGj {
  color: #2c242c;
  font-size: 18px;
  font-weight: 500; }

.lead-module__lead-control___3SnNQ {
  display: -ms-flexbox;
  display: flex; }
  .lead-module__lead-control___3SnNQ > div:first-child {
    margin-right: 0%; }

.lead-module__lead-info___3StNA {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex: 1;
      flex: 1;
  margin-top: calc(35px - 25px); }
  .lead-module__lead-info___3StNA > div {
    margin-right: 60px;
    margin-top: 25px; }
  .lead-module__lead-info___3StNA > div:last-child {
    margin-right: 0px; }

.lead-module__label___3I7It {
  color: #a9a9a9;
  font-size: 10px;
  font-weight: 700;
  text-transform: uppercase; }

.lead-module__info-text___1grCU {
  color: #2c242c;
  font-size: 14px;
  font-weight: 400; }

.lead-module__info___KpBRs .lead-module__label___3I7It {
  margin-bottom: 8px; }

.lead-module__open-columns___3cYVA {
  display: -ms-flexbox;
  display: flex;
  margin-top: 40px; }
  .lead-module__open-columns___3cYVA > div {
    -ms-flex: 1;
        flex: 1; }
  .lead-module__open-columns___3cYVA > div:first-child {
    margin-right: 15px; }
  .lead-module__open-columns___3cYVA > div:last-child {
    margin-left: 15px; }
  .lead-module__open-columns___3cYVA > div:only-child {
    margin-left: 0px;
    margin-right: 0px; }

.lead-module__open-label-headline___1wwgJ {
  color: #2c242c;
  font-size: 18px;
  font-weight: 500; }

.lead-module__msg___3fd9z .lead-module__open-label-headline___1wwgJ {
  margin-bottom: 18px; }

.lead-module__msg-text___22TBa {
  color: #2c242c;
  font-size: 13px;
  font-weight: 400;
  line-height: 24px; }

.lead-module__intrestedIn___3lwGW .lead-module__open-label-headline___1wwgJ {
  margin-bottom: 18px; }

.lead-module__packages___3lpYX .lead-module__label___3I7It {
  margin-bottom: 8px; }

.lead-module__package-list___2uYg8 {
  display: -ms-flexbox;
  display: flex;
  margin: -15px;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap; }

.lead-module__package___3Tvl2 {
  margin: 15px;
  -ms-flex: 1;
      flex: 1;
  min-height: 111px;
  border-radius: 4px;
  border: 1px solid #f0f0f0;
  background-color: #ffffff;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  min-width: calc(50% - 30px);
  -ms-flex-direction: column;
      flex-direction: column;
  padding: 10px; }
  .lead-module__package___3Tvl2 > p:nth-child(2) {
    margin-top: 6px; }
  .lead-module__package___3Tvl2 .lead-module__package-name___1MXaq {
    color: #2c242c;
    font-size: 14px;
    font-weight: 400;
    text-align: center; }
  .lead-module__package___3Tvl2 .lead-module__package-price___3-Xor {
    color: #2c242c;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0.64px; }

.lead-module__offers___6lfQ8 {
  margin-top: 30px; }
  .lead-module__offers___6lfQ8 .lead-module__label___3I7It {
    margin-bottom: 8px; }
  .lead-module__offers___6lfQ8 .lead-module__offer___2-zdl {
    margin-top: 10px; }
  .lead-module__offers___6lfQ8 .lead-module__offer___2-zdl:first-child {
    margin-top: 0px; }

.lead-module__offer___2-zdl {
  border-radius: 4px;
  border: 1px solid #f0f0f0;
  background-color: #ffffff;
  padding: 13px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-align: center;
      align-items: center; }
  .lead-module__offer___2-zdl .lead-module__offer-name___3iqD9 {
    color: #2c242c;
    font-size: 14px;
    font-weight: 400; }
  .lead-module__offer___2-zdl .lead-module__offer-price___3Lpuh {
    color: #2c242c;
    font-size: 14px;
    font-weight: 700; }
