.checkbox-module__checkbox-label___z2QF5 {
  -ms-flex-align: center;
      align-items: center;
  display: -ms-flexbox;
  display: flex; }

.checkbox-module__icon___1vSb9 {
  -ms-flex-line-pack: center;
      align-content: center;
  display: -ms-flexbox;
  display: flex;
  margin-right: 6px; }

.checkbox-module__text___2Xwbh {
  color: #2c242c;
  font-size: 14px;
  font-weight: 400; }
  .checkbox-module__text___2Xwbh:hover {
    color: #0076ff;
    cursor: pointer;
    font-weight: 400; }

.checkbox-module__selected___13m86 {
  font-weight: 400; }
