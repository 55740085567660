.button-module__button___2C7K1 {
  display: inline-block;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  border-radius: 4px; }
  .button-module__button___2C7K1 p {
    color: #ffffff;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 0.13px; }

.button-module__content___3MjDD {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: justify;
      justify-content: space-between;
  height: 100%; }

.button-module__icon___S_Lr5 {
  margin-left: 10px; }

.button-module__button-primary___1E5qq {
  background-color: #0076ff;
  height: 40px;
  padding: 10px;
  padding-left: 13px;
  padding-right: 13px;
  color: #ffffff;
  box-shadow: 0 8px 14px rgba(0, 118, 255, 0.2); }
  .button-module__button-primary___1E5qq .button-module__icon___S_Lr5 {
    font-size: 14px; }
  .button-module__button-primary___1E5qq p {
    font-size: 14px; }
  .button-module__button-primary___1E5qq:hover {
    background-color: #1a84ff; }

.button-module__button-premium___1hphM {
  background-color: #F4C24E;
  padding: 6px 7px; }
  .button-module__button-premium___1hphM p {
    color: #ffffff; }
  .button-module__button-premium___1hphM svg, .button-module__button-premium___1hphM path {
    fill: #ffffff !important; }
  .button-module__button-premium___1hphM:hover {
    background-color: #f5ca66; }

.button-module__button-control___1tKBz {
  height: 30px;
  font-size: 13px;
  border-radius: 4px;
  background-color: #0076ff;
  padding: 2px 9px; }
  .button-module__button-control___1tKBz .button-module__content___3MjDD {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row-reverse;
        flex-direction: row-reverse; }
  .button-module__button-control___1tKBz p {
    color: #ffffff; }
  .button-module__button-control___1tKBz svg, .button-module__button-control___1tKBz path {
    font-size: 14px;
    fill: #ffffff !important; }
  .button-module__button-control___1tKBz:hover {
    background-color: #1a84ff; }
  .button-module__button-control___1tKBz .button-module__icon___S_Lr5 {
    margin-left: 0px;
    margin-right: 6px; }
  .button-module__button-control___1tKBz.button-module__active___4zqnK {
    background: red; }
    .button-module__button-control___1tKBz.button-module__active___4zqnK p {
      color: #ffffff; }
    .button-module__button-control___1tKBz.button-module__active___4zqnK svg, .button-module__button-control___1tKBz.button-module__active___4zqnK path {
      fill: #ffffff !important; }

.button-module__button-light___1A2s_ {
  height: 30px;
  font-size: 13px;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 2px 9px; }
  .button-module__button-light___1A2s_ .button-module__content___3MjDD {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row-reverse;
        flex-direction: row-reverse; }
  .button-module__button-light___1A2s_ p {
    color: #ffffff; }
  .button-module__button-light___1A2s_ svg, .button-module__button-light___1A2s_ path {
    font-size: 14px;
    fill: #ffffff !important; }
  .button-module__button-light___1A2s_:hover {
    background-color: rgba(255, 255, 255, 0.15); }
  .button-module__button-light___1A2s_ .button-module__icon___S_Lr5 {
    margin-left: 0px;
    margin-right: 6px; }
  .button-module__button-light___1A2s_.button-module__active___4zqnK {
    background: white; }
    .button-module__button-light___1A2s_.button-module__active___4zqnK p {
      color: #0076ff; }
    .button-module__button-light___1A2s_.button-module__active___4zqnK svg, .button-module__button-light___1A2s_.button-module__active___4zqnK path {
      fill: #0076ff !important; }
