.quote-module__container___25KiY {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  position: relative; }

.quote-module__header___1T4r8 {
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 32px; }

.quote-module__row-inputs___bLLps {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  margin: 0px -5px; }
  .quote-module__row-inputs___bLLps > * {
    -ms-flex: 1;
        flex: 1;
    margin: 0px 5px; }

.quote-module__header-item___tV5EJ {
  -ms-flex: 1;
      flex: 1; }
  .quote-module__header-item___tV5EJ > * {
    margin-top: 35px; }
  .quote-module__header-item___tV5EJ > *:first-child {
    margin-top: 0px; }
  .quote-module__header-item___tV5EJ:first-child {
    margin-right: 20px; }

.quote-module__description___gdgx1 {
  margin-bottom: 30px; }

.quote-module__name-input-wrap___1GUqO {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }
  .quote-module__name-input-wrap___1GUqO > *:first-child {
    -ms-flex: 1;
        flex: 1; }
  .quote-module__name-input-wrap___1GUqO > *:last-child {
    margin-left: 15px; }
