.modal-close__close___2bZOR {
  position: absolute;
  right: 10px;
  top: 10px; }

.modal-close__modal-content___1hEe- {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column; }
