.button-module__button___3EMDN {
  height: 35px;
  border-radius: 4px;
  padding-top: 11px;
  padding-bottom: 11px;
  padding-left: 12px;
  padding-right: 12px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  outline: none; }
  .button-module__button___3EMDN p {
    margin: 0; }

.button-module__green-button___3AbzA {
  background-color: white;
  border: 1px solid #49e23b;
  color: #49e23b; }
  .button-module__green-button___3AbzA:hover {
    background-color: #49e23b;
    color: white; }

.button-module__red-button___Ewg12 {
  background-color: #ff0000;
  color: white; }
  .button-module__red-button___Ewg12:hover {
    background-color: #ff9999; }

.button-module__empty-primary___3pU29 {
  background-color: white;
  color: #49e23b;
  border: 1px solid #49e23b;
  min-width: 121.63px; }

.button-module__secondary___1AbY6 {
  border: 1px solid #0076ff;
  background-color: white;
  color: #0076ff; }
  .button-module__secondary___1AbY6:hover {
    background-color: white; }

.button-module__appAction___3_85v {
  border: 1px solid #a7b6c7;
  color: black;
  background-color: white; }
  .button-module__appAction___3_85v:hover {
    background-color: #f2f2f2; }

.button-module__text___1z3a4 {
  font-size: 18px;
  font-weight: 500; }

.button-module__icon___3F5nX {
  padding-left: 8px; }
