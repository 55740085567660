.area-module__area___TFTDi {
  width: 100%;
  border: 1px solid #3d343d;
  background-color: #2c242c;
  border-radius: 4px;
  padding: 10px;
  font-size: 14px;
  outline: none;
  color: white;
  min-height: 200px;
  max-height: 500px; }
  .area-module__area___TFTDi:focus {
    border: 1px solid #4b404b; }
