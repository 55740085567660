.resize-module__resizer___1U1-x {
  width: 8px;
  background-color: #2c242c;
  cursor: col-resize;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  transform: translateZ(0px); }

.resize-module__drag-container___PeKw5 {
  top: 0; }

.resize-module__drag-item___3Q1-O {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: white;
  margin-top: 3px;
  margin-bottom: 3px; }
