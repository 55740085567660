.entity-module__container___2zgTH {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  position: relative; }

.entity-module__header___1NiAG {
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 32px; }

.entity-module__header-item___2mrTw {
  -ms-flex: 1;
      flex: 1; }
  .entity-module__header-item___2mrTw > * {
    margin-top: 35px; }
  .entity-module__header-item___2mrTw > *:first-child {
    margin-top: 0px; }
  .entity-module__header-item___2mrTw:first-child {
    margin-right: 20px; }

.entity-module__description___1sbeZ {
  margin-bottom: 30px; }

.entity-module__delete-icon___2PCv2 {
  position: absolute;
  right: -25px;
  top: 10px; }

.entity-module__name-input-wrap___3dGzy {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }
  .entity-module__name-input-wrap___3dGzy > *:first-child {
    -ms-flex: 1;
        flex: 1; }
  .entity-module__name-input-wrap___3dGzy > *:last-child {
    margin-left: 15px; }
