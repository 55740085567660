.page-module__container___2M9Oh:last-child .page-module__item___1ieip .page-module__line___2aDAS {
  display: none; }

.page-module__item___1ieip {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  position: relative;
  cursor: pointer;
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 0.46px;
  line-height: 15px;
  padding-right: 4px;
  white-space: nowrap;
  text-transform: capitalize;
  color: #2c242c; }
  .page-module__item___1ieip span {
    width: 12px;
    margin-right: 6px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: center;
        justify-content: center; }
  .page-module__item___1ieip:hover {
    color: rgba(0, 118, 255, 0.8); }

.page-module__item-active___2QgQb {
  color: #0076ff; }
  .page-module__item-active___2QgQb .page-module__line___2aDAS {
    background: #0076ff; }

.page-module__item-premium___47U_i {
  color: #2c242c; }

.page-module__item-active___2QgQb.page-module__item-premium___47U_i {
  color: #682eff; }
  .page-module__item-active___2QgQb.page-module__item-premium___47U_i .page-module__line___2aDAS {
    background: #682eff; }

.page-module__editing-page___4Fqsl {
  position: absolute;
  bottom: -8px;
  border-radius: 4px;
  background-color: rgba(0, 118, 255, 0.2);
  width: calc(100% + 10px);
  height: 3px; }

.page-module__icon___81iiM {
  position: absolute;
  right: -14px; }
