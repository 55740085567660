.opportunity-module__checkbox___1Dmef {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin: -10px -15px; }

.opportunity-module__checkbox-item___31i9i {
  margin: 10px 15px; }

.opportunity-module__dropdown___2UZjj {
  margin-top: 40px;
  max-width: 230px; }

.opportunity-module__dropdown-item___2wagF {
  margin-top: 30px; }
  .opportunity-module__dropdown-item___2wagF:first-child {
    margin-top: 0px; }
