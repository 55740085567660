.speaker-module__container___IljmE {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  position: relative; }

.speaker-module__header___3eVOC {
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 32px; }

.speaker-module__row-inputs___2_6tE {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  margin: 0px -5px; }
  .speaker-module__row-inputs___2_6tE > * {
    margin: 0px 5px;
    -ms-flex: 1;
        flex: 1; }

.speaker-module__header-item___3C3UF {
  -ms-flex: 1;
      flex: 1; }
  .speaker-module__header-item___3C3UF > * {
    margin-top: 35px; }
  .speaker-module__header-item___3C3UF > *:first-child {
    margin-top: 0px; }
  .speaker-module__header-item___3C3UF:first-child {
    margin-right: 20px; }

.speaker-module__description___2kANH {
  margin-bottom: 30px; }

.speaker-module__name-input-wrap___HuQ3h {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }
  .speaker-module__name-input-wrap___HuQ3h > *:first-child {
    -ms-flex: 1;
        flex: 1; }
  .speaker-module__name-input-wrap___HuQ3h > *:last-child {
    margin-left: 15px; }
