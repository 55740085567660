.control-module__package-control___bpVUd {
  height: 60px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  width: 40px;
  margin-bottom: -1px; }

.control-module__line___4CpXJ {
  width: 21px;
  height: 1px;
  background: #d3dbe4;
  margin-top: 8px;
  margin-bottom: 3px; }

.control-module__menu-container___2NcJE {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center; }

.control-module__menu-icon___35H5Q {
  position: relative; }

.control-module__more___WvaQU {
  display: block;
  position: relative; }
  .control-module__more___WvaQU span {
    z-index: 2; }

.control-module__click-zone___9yZMR {
  position: absolute;
  height: 15px;
  width: 30px;
  top: -6px;
  left: -7px;
  z-index: 1; }
