.account-module__container___1cZ85 {
  margin: 12px 7px;
  margin-top: 0px; }

.account-module__headline___NzDc2 {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  padding-bottom: 5px; }

.account-module__item___3FlRR {
  margin: 17px 0px;
  color: #ffffff;
  font-size: 13px;
  font-weight: 500;
  line-height: 15px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  text-decoration: none; }
  .account-module__item___3FlRR:hover {
    text-decoration: underline; }
  .account-module__item___3FlRR div {
    opacity: 0.7; }

.account-module__item-active___-Z2bb {
  color: #0076ff; }
