.color-module__picker___R2dwh {
  min-height: 40px;
  border-radius: 4px;
  border: 1px solid #3d343d;
  background-color: #2c242c;
  width: 100%;
  border-radius: 4px;
  padding: 7px;
  cursor: pointer;
  z-index: 9999;
  position: relative; }
  .color-module__picker___R2dwh:hover {
    background-color: #4b404b; }

.color-module__row___3w6jH {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-align: center;
      align-items: center; }

.color-module__icon___1DejP {
  margin-right: 7px;
  margin-left: 14px;
  transform: rotate(0deg);
  transition: 0.2s transform; }

.color-module__icon-active___3u6f_ {
  transform: rotate(180deg); }

.color-module__colors___1G_O3 {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex: 1;
      flex: 1;
  position: relative; }
  .color-module__colors___1G_O3 > * {
    -ms-flex: 1;
        flex: 1; }
  .color-module__colors___1G_O3 > *:first-child > .color-module__color___3peeb {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px; }
  .color-module__colors___1G_O3 > *:last-child > .color-module__color___3peeb {
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px; }

.color-module__presets___3gvPG {
  max-height: 0px;
  transition: max-height 0.3s, margin-top 0.3s, margin-bottom 0.3s, opacity 0.3s;
  will-change: max-height, opacity;
  margin-top: -5px;
  margin-bottom: 0px;
  opacity: 0;
  pointer-events: none; }
  .color-module__presets___3gvPG > div {
    margin-top: 12px;
    margin-bottom: 5px;
    margin-right: 30px; }

.color-module__preset-active___3CC6I {
  pointer-events: all;
  opacity: 1;
  margin-top: 12px;
  margin-bottom: 2px;
  max-height: 700px;
  transition: max-height 0.3s, opacity 0.3s; }

.color-module__preset___3Qgny {
  border: 1px solid transparent; }
  .color-module__preset___3Qgny:hover {
    border: 1px solid #0076ff; }

.color-module__color___3peeb {
  height: 26px;
  position: relative; }
  .color-module__color___3peeb:first-child .color-module__picker-color___2GtsS {
    left: 85%; }
  .color-module__color___3peeb:last-child .color-module__picker-color___2GtsS {
    left: 25%; }

.color-module__picker-color___2GtsS {
  position: absolute;
  z-index: 100;
  transform: translateX(-50%); }

.color-module__label___3-1M6 {
  color: #6f7882;
  font-size: 10px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 6px; }

.color-module__color-selected-text___1u4GG {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: justify;
      justify-content: space-between;
  padding: 0px 10px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  color: #ffffff;
  font-size: 10px;
  font-weight: 500;
  line-height: 12px;
  pointer-events: none; }
