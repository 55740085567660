.additional-module__element___1nJ-u {
  margin-bottom: -10px;
  margin-top: 33px; }

.additional-module__container___b8wBF {
  -ms-flex-align: center;
      align-items: center;
  display: -ms-flexbox;
  display: flex;
  margin: 0px -5px; }
  .additional-module__container___b8wBF > div {
    -ms-flex: 1;
        flex: 1;
    position: relative;
    margin: 0px 5px; }

.additional-module__metric-value___2_Anv {
  max-width: 200px; }

.additional-module__metric-block___2Z5wQ {
  position: absolute;
  top: -17px; }

.additional-module__metric-instance___1wUTz {
  margin-top: 10px;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }
  .additional-module__metric-instance___1wUTz > *:first-child {
    -ms-flex: 1;
        flex: 1; }

.additional-module__delete-icon___5tEXb {
  cursor: pointer;
  margin-left: 15px; }

.additional-module__add-instance___29UPl {
  margin-top: 30px; }

.additional-module__icons___2nFXM {
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  right: 13px;
  top: 50%;
  transform: translateY(-50%); }
  .additional-module__icons___2nFXM div {
    margin-right: 13px; }
  .additional-module__icons___2nFXM div:last-child {
    margin-right: 0px; }

.additional-module__textarea___2yXJv {
  margin-top: 25px; }

.additional-module__button___3NEE2 {
  margin-top: 5px;
  margin-left: auto;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: end;
      justify-content: flex-end; }
