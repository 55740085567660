.headline {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.5px;
  color: #0076ff;
  background: white; }

.small-headline {
  color: black;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.2px; }

.package-cell {
  min-width: 120px;
  height: 60px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex: 1;
      flex: 1; }
  .package-cell p {
    margin: 0; }

.cell-border-top {
  border-top: 1px solid #e8edf3;
  margin-bottom: -1px; }

.packagecreator-module__creator-margin___2x9KU {
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 1;
      flex: 1;
  -ms-flex-direction: column;
      flex-direction: column;
  margin-top: 15px;
  padding-left: 40px;
  width: 100%; }

.packagecreator-module__container___iUY0b {
  display: -ms-flexbox;
  display: flex; }

.packagecreator-module__benefit-headline___S8PGm {
  margin-bottom: -1px;
  margin-top: 15px; }

.packagecreator-module__creatorWrap___39iOq {
  -ms-flex: 1;
      flex: 1;
  position: relative;
  z-index: 3; }

.packagecreator-module__creator___3axrI {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: end;
      justify-content: flex-end;
  padding-right: 10px;
  position: relative;
  will-change: filter; }

.packagecreator-module__creator-blur___2ysUf {
  filter: blur(3px);
  pointer-events: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.packagecreator-module__packages___2Q4WP {
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 1;
      flex: 1;
  -ms-flex-direction: column;
      flex-direction: column;
  min-width: 130px; }

.packagecreator-module__package-items___2VEZ6 {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column; }
  .packagecreator-module__package-items___2VEZ6 > div:last-child {
    border-bottom: 1px solid #e8edf3; }

.packagecreator-module__tiers___5S7NV {
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 1;
      flex: 1;
  margin-bottom: -1px; }

.packagecreator-module__tier-header___PbR91 {
  -ms-flex-align: center;
      align-items: center;
  display: -ms-flexbox;
  display: flex;
  position: relative; }

.packagecreator-module__tier-arrows___1FPT7 {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  position: absolute;
  right: calc(49px - 10px);
  top: 0;
  width: calc(53% + 18px); }

.packagecreator-module__connections___Qo8f1 {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  position: relative;
  transform: translateZ(0);
  will-change: filter; }

.packagecreator-module__connections-blur___1-Ms6 {
  filter: blur(5px); }

.packagecreator-module__connection-row___3YV3G {
  display: -ms-flexbox;
  display: flex; }
  .packagecreator-module__connection-row___3YV3G div:last-child {
    border-right: 0; }
  .packagecreator-module__connection-row___3YV3G div:first-child {
    border-left: 0; }
  .packagecreator-module__connection-row___3YV3G:last-child {
    margin-bottom: 1px; }

.packagecreator-module__packages-headline___2gqd9 {
  color: #a9a9a9;
  font-size: 14px;
  font-weight: 500s;
  letter-spacing: 0.3px; }

.packagecreator-module__odd___2mozk {
  background-color: #fafcfe; }

.packagecreator-module__add-new-container___3-x56 {
  -ms-flex-align: center;
      align-items: center;
  cursor: pointer;
  display: -ms-flexbox;
  display: flex;
  left: -15px;
  margin-top: 18px;
  max-width: 300px;
  position: relative;
  z-index: -1; }
  .packagecreator-module__add-new-container___3-x56 span {
    margin-right: 9px; }
  .packagecreator-module__add-new-container___3-x56 p {
    color: #0076ff;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.3px;
    margin: 0;
    margin-left: 5px; }
  .packagecreator-module__add-new-container___3-x56:hover p {
    color: #66adff; }
  .packagecreator-module__add-new-container___3-x56:hover svg, .packagecreator-module__add-new-container___3-x56:hover path {
    fill: #66adff; }

.packagecreator-module__scroll-conainer___1gbCa {
  overflow-x: scroll;
  width: 53%;
  max-width: 350px; }

.packagecreator-module__line___3nQfT {
  box-shadow: 3px 0 5px -1px rgba(135, 150, 166, 0.42);
  height: 100%;
  height: calc(100% - 15px);
  opacity: 0;
  position: absolute;
  right: 0;
  top: 15px;
  top: 0;
  transition: 0.2s opacity;
  width: 4px;
  z-index: 999; }

.packagecreator-module__line-active___jzd6k {
  opacity: 1;
  transition: 0.2s opacity; }

.packagecreator-module__controls___2ZXSd {
  border-left: 1px solid #e8edf3;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  height: 100%;
  margin-top: 15px;
  position: relative; }

.packagecreator-module__controls-line___388gW {
  box-shadow: -3px 0 5px -1px rgba(135, 150, 166, 0.42);
  height: 100%;
  left: -1px;
  opacity: 0.5;
  position: absolute;
  width: 7px;
  z-index: 250; }

.packagecreator-module__tier-add___3qm3a {
  cursor: pointer;
  position: absolute; }

.packagecreator-module__control-item___NXi44 {
  -ms-flex-align: center;
      align-items: center;
  display: -ms-flexbox;
  display: flex;
  height: 60px;
  -ms-flex-pack: center;
      justify-content: center;
  margin-bottom: -1px;
  width: 40px; }

.packagecreator-module__white-background___2nFAG {
  background-color: rgba(255, 255, 255, 0.85);
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 10; }
