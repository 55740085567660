.menu-module__menu___2gLt9 {
  border-radius: 4px;
  border: 1px solid #f0f0f0;
  background-color: #ffffff;
  padding: 4px; }

.menu-module__depth___2XoJY {
  position: absolute;
  left: 100%;
  top: 0px;
  z-index: 999; }

.menu-module__item___3BHCX {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-align: center;
      align-items: center;
  padding: 4px 10px;
  border-radius: 4px;
  color: #2c242c;
  font-size: 13px;
  font-weight: 500;
  line-height: 15px; }
  .menu-module__item___3BHCX div {
    margin-right: 5px; }
  .menu-module__item___3BHCX:hover {
    background-color: #f0f0f0; }

.menu-module__modal___1KC8e .menu-module__text___1xdYg {
  color: #ff0000;
  line-height: 23px;
  font-weight: 700; }

.menu-module__button___2WdlX {
  box-shadow: 0 8px 14px rgba(0, 118, 255, 0.2);
  border: none;
  border-radius: 4px;
  background-color: #0076ff;
  height: 40px;
  width: 100%;
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  cursor: pointer;
  padding: 0px 15px 0px 15px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-align: center;
      align-items: center;
  outline: none; }
  .menu-module__button___2WdlX svg, .menu-module__button___2WdlX path {
    fill: white; }
  .menu-module__button___2WdlX:hover {
    background-color: #1a84ff; }
  .menu-module__button___2WdlX:disabled {
    background-color: #cecece;
    box-shadow: 0 8px 14px rgba(140, 140, 140, 0.2); }
