.servicebot-pricing-table {
  margin-top: 30px !important; }
  .servicebot-pricing-table * {
    text-align: left !important; }

.servicebot-pricing-table .__tier .__header {
  font-size: 21px;
  padding: 0 24px !important;
  margin-top: 20px; }

.servicebot-pricing-table .__tier .__body .__tier-plan {
  padding: 0px 24px !important; }

.servicebot-pricing-table .__tier .__header h3 {
  font-size: 22px !important;
  font-weight: bold !important;
  color: #0076ff !important;
  margin: 0 !important;
  margin-bottom: -3px !important; }

.servicebot-pricing-table .__tier .__body .__tier-plan .__price {
  margin-top: 5px; }

.servicebot-pricing-table .__tier .__body .__tier-plan .__amount {
  margin-top: 12px;
  font-size: 26px !important; }

.servicebot-divider.servicebot-divider-horizontal {
  display: none; }

.__section-title.Pricing {
  display: none; }

.__trials {
  text-align: center !important;
  padding-bottom: 0 !important; }

.__footer {
  margin-top: 25px;
  margin-bottom: 9px; }

.servicebot-tag.servicebot-tag-green {
  margin-bottom: 15px; }

.servicebot-pricing-table .__tier .__footer {
  padding: 0px !important; }

.servicebot-pricing-table .servicebot-btn-primary {
  border-radius: 4px !important;
  height: 44px !important;
  box-shadow: 0 8px 14px 0 rgba(0, 118, 255, 0.2) !important;
  background-color: #0076ff !important;
  width: 85% !important;
  margin: 0px 24px !important;
  box-sizing: border-box !important;
  font-weight: bold !important; }

.servicebot-pricing-table .__tier-2 .__header h3 {
  color: #e10b5d !important; }

.servicebot-pricing-table > * {
  margin-left: 30px; }

.servicebot-pricing-table > *:first-child {
  margin-left: 0px; }

.__tier.__tier-1 .servicebot-row.__tier-plan.__plan-1:after {
  position: relative;
  line-height: 35px;
  content: "3 Active Proposals\A     Premium CRM \A     Premium Proposal Sections \A     Premium Support \A     Premium Proposal Customization \A     Premium Notifications & Metrics \A     Rename & Reorder Pages";
  white-space: pre-line; }

.__tier.__tier-2 .servicebot-row.__tier-plan.__plan-1:after {
  position: relative;
  line-height: 35px;
  content: "Unlimited proposals \A   Premium CRM \A   Premium Proposal Sections \A   Premium Support \A   Premium Proposal Customization \A   Premium Notifications & Metrics \A   Rename & Reorder Pages \A   Remove Sponseasy Watermark \A   Customization with HTML/ CSS \A   PDF Export \A   Custom Proposal URL";
  white-space: pre-line; }

.pricing-module__pricing-list___8TF9C {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between; }
  .pricing-module__pricing-list___8TF9C > *:first-child {
    width: 100%;
    margin-right: 30px; }

.pricing-module__demo___1tkyw {
  max-width: 30%;
  border-radius: 4px;
  padding: 30px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background-color: #f9f9f9;
  margin-top: 88px; }
  .pricing-module__demo___1tkyw h2 {
    font-size: 22px;
    font-weight: bold;
    color: #2c242c;
    margin: 0 0 10px; }
  .pricing-module__demo___1tkyw p {
    font-size: 13px;
    line-height: 18px;
    color: #2c242c; }

.pricing-module__content___kZp6g {
  padding: 50px 80px;
  max-width: 1300px;
  margin: 0 auto;
  padding-bottom: 0px; }

.pricing-module__header___wtutB {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-align: center;
      align-items: center;
  margin-bottom: 100px; }

.pricing-module__headline___15WDC {
  margin: 0;
  color: #2c242c;
  font-size: 29px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: -1px; }

.pricing-module__tiers___2kMnf {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  margin-left: -24px;
  margin-right: -24px; }
  .pricing-module__tiers___2kMnf > div {
    margin-left: 24px;
    margin-right: 24px; }

.pricing-module__faq____tTCW {
  background-color: white;
  padding: 80px;
  padding-top: 70px;
  max-width: 1300px;
  margin: 0 auto; }
  .pricing-module__faq____tTCW > p {
    color: #2c242c;
    font-size: 22px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left; }

.pricing-module__faqs___17lcr {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin: 0 auto;
  max-width: 1300px; }
  .pricing-module__faqs___17lcr .pricing-module__faq-item___2-zML {
    width: 50%;
    margin-top: 50px;
    display: -ms-flexbox;
    display: flex; }

.pricing-module__faq-item-content___1Rfrd {
  max-width: 460px;
  width: 100%; }
  .pricing-module__faq-item-content___1Rfrd > p:first-child {
    color: #2c242c;
    font-size: 18px;
    font-weight: 700;
    line-height: 30px;
    margin-bottom: 5px; }
  .pricing-module__faq-item-content___1Rfrd > p:last-child {
    color: #2c242c;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px; }

.pricing-module__pricing-switch___dJNN1 {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }

.pricing-module__pricing-description___1z3gq {
  color: #a9a9a9;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: -1px;
  line-height: 21.01px;
  cursor: pointer; }

.pricing-module__pricing-billing-time___2zqVD {
  color: #a9a9a9;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: -1px;
  line-height: 21.01px;
  cursor: pointer; }

.pricing-module__active-range___3d4xq {
  color: #2c242c; }
  .pricing-module__active-range___3d4xq span {
    color: #0076ff; }

.pricing-module__social-proof___1nfLn {
  margin-top: 160px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }

.pricing-module__social-text___3Njac {
  margin-left: 85px; }
  .pricing-module__social-text___3Njac > p {
    color: #2c242c;
    font-size: 24px;
    font-weight: 400;
    line-height: 37.46px; }

.pricing-module__social-person___7HApS {
  color: #2c242c;
  font-size: 14px;
  font-weight: 700;
  margin-top: 30px; }
  .pricing-module__social-person___7HApS span {
    font-weight: 400; }

.pricing-module__cupon-code___3o_oZ {
  font-size: 12px;
  position: absolute;
  right: 0px;
  top: -20px;
  cursor: pointer;
  color: #cecece;
  font-size: 10px;
  font-weight: 700;
  line-height: 13px;
  text-decoration: underline;
  text-transform: uppercase; }
