.templates-module__container___3mJ9J {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  height: 100%; }

.templates-module__content___2LBFu {
  max-width: 750px;
  width: 90%;
  margin-top: 100px;
  margin-bottom: 50px; }

.templates-module__image___2gEFC {
  position: absolute;
  right: 0px;
  top: 0px; }

.templates-module__label___wrO8p {
  color: #a9a9a9;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  margin-bottom: 16px; }

.templates-module__proposal-new___rRPTQ {
  border-radius: 4px;
  border: 1px solid #f0f0f0;
  background-color: #ffffff;
  padding: 35px 45px 45px 45px;
  overflow: hidden;
  position: relative; }
  .templates-module__proposal-new___rRPTQ h2 {
    color: #2c242c;
    font-size: 27px;
    font-weight: 700;
    line-height: 32px;
    margin-bottom: 20px; }

.templates-module__button___26maV {
  width: 199px;
  height: 40px;
  box-shadow: 0 8px 14px rgba(0, 118, 255, 0.2);
  border-radius: 4px;
  background-color: #0076ff;
  color: #ffffff;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  font-size: 14px;
  font-weight: 700;
  -ms-flex-pack: center;
      justify-content: center;
  cursor: pointer;
  text-decoration: none; }
  .templates-module__button___26maV:hover {
    background-color: #1a84ff; }
  .templates-module__button___26maV div {
    margin-left: 13px; }

.templates-module__example___1fyvO {
  margin-top: 60px; }

.templates-module__example-label___27xPL {
  color: #2c242c;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 20px; }

.templates-module__examples___1VPqC {
  margin-top: 20px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin: -12px; }
  .templates-module__examples___1VPqC > div {
    margin: 12px; }
