.dropdown-module__dropdown___dP9xz {
  position: absolute;
  width: 150px;
  background: white;
  border: 1px solid #0076ff;
  -o-border-image: linear-gradient(#0076ff, rgba(0, 0, 0, 0)) 1/1px;
     border-image: linear-gradient(#0076ff, rgba(0, 0, 0, 0)) 1/1px;
  left: 50%;
  transform: translateX(-50%);
  padding: 0px 5px 0px 5px;
  top: 25px;
  box-shadow: 0 7px 12px rgba(33, 34, 34, 0.04);
  z-index: 500; }

.dropdown-module__option___Dax88 {
  display: -ms-flexbox;
  display: flex;
  margin-top: 13px;
  margin-bottom: 13px;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  position: relative;
  -ms-flex-align: center;
      align-items: center; }
  .dropdown-module__option___Dax88 > p {
    margin: 0;
    margin-left: 6px;
    color: #000;
    font-size: 13px;
    font-weight: 400; }
  .dropdown-module__option___Dax88:hover {
    text-decoration: underline; }

.dropdown-module__extender___11jKK {
  position: absolute;
  width: 140px;
  height: 30px;
  z-index: 400;
  top: -16px;
  left: -1px; }

.dropdown-module__delete-text___UC4TP {
  color: #000000;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.17px;
  margin: 0;
  margin-bottom: 8px !important; }

.dropdown-module__confirm-delete___3_4Ds {
  position: absolute;
  box-shadow: 0 7px 12px rgba(33, 34, 34, 0.04);
  background-color: #ffffff;
  left: 50%;
  top: 30px;
  transform: translateX(-50%);
  padding: 10px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-direction: column;
      flex-direction: column;
  z-index: 800;
  border: 1px solid #0076ff;
  -o-border-image: linear-gradient(#0076ff, rgba(0, 0, 0, 0)) 1/1px;
     border-image: linear-gradient(#0076ff, rgba(0, 0, 0, 0)) 1/1px;
  max-width: 220px;
  width: 120px; }
  .dropdown-module__confirm-delete___3_4Ds:before {
    content: "";
    position: absolute;
    width: 14px;
    height: 14px;
    border-top: 1px solid #0076ff;
    border-left: 1px solid #0076ff;
    top: -3px;
    left: 50%;
    border-radius: 1px;
    background: white;
    transform: rotate(45deg) translateX(-50%); }
