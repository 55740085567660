.description-module__wrapper___2U5TU {
  margin-top: 30px;
  margin-bottom: 50px;
  position: relative; }
  .description-module__wrapper___2U5TU:last-child {
    margin-bottom: 30px; }

.description-module__description___1Twxf {
  display: -ms-flexbox;
  display: flex;
  margin-top: 10px; }

.description-module__file-upload___14ZjN {
  -ms-flex: 1;
      flex: 1;
  margin-left: 25px; }

.description-module__text-area___30bO3 {
  width: 70%; }

.description-module__file-active___1S7aK {
  height: 140px;
  max-width: 200px; }

.description-module__headline___X7UCx {
  margin-bottom: 50px;
  display: -ms-flexbox;
  display: flex; }
  .description-module__headline___X7UCx > *:first-child {
    -ms-flex: 1;
        flex: 1; }
  .description-module__headline___X7UCx .description-module__delete-icon___GZL9h {
    margin-left: 15px;
    margin-top: 10px; }
