.places-module__input-container___CXzey {
  position: relative; }

.places-module__input___3hcqt {
  border: 1px solid #cecece;
  border-radius: 4px;
  color: #2c242c;
  font-size: 14px;
  font-weight: 400;
  height: 40px;
  outline: none;
  padding-left: 12px;
  transition: 100ms border;
  width: 100%; }
  .places-module__input___3hcqt:hover {
    border: 1px solid #0076ff; }
  .places-module__input___3hcqt:focus {
    border: 1px solid #0076ff;
    box-shadow: inset 0 0 0 2px rgba(0, 118, 255, 0.2); }

.places-module__icon___3qCx0 {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%); }
