.nav-module__nav___2VyUk {
  height: 50px;
  background-color: #2c242c;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-align: center;
      align-items: center;
  color: #ffffff;
  position: fixed;
  top: 0;
  z-index: 10;
  width: 100%; }

.nav-module__item___33n6T {
  padding: 0px 20px;
  height: 100%;
  -ms-flex-align: center;
      align-items: center;
  display: -ms-flexbox;
  display: flex; }

.nav-module__nav-item___2-eFl {
  cursor: pointer; }
  .nav-module__nav-item___2-eFl:hover {
    background: #3a2f3a; }

.nav-module__go-back___2a8wm {
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px; }

.nav-module__nav-item-full___F7D4q {
  padding-left: 5px;
  -ms-flex: 1;
      flex: 1;
  font-size: 18px;
  font-weight: 500; }

.nav-module__preview___-uDnM {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  cursor: pointer; }

.nav-module__publish___1I9cv {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center; }

.nav-module__preview-icon___1UUzL {
  padding-left: 10px;
  font-size: 12px; }
  .nav-module__preview-icon___1UUzL svg, .nav-module__preview-icon___1UUzL path {
    fill: #ffffff; }

.nav-module__link___1CU3y {
  display: -ms-flexbox;
  display: flex;
  padding: 6px 12px;
  border-radius: 4px;
  background-color: #39303a;
  color: #645d65;
  font-size: 13px;
  font-weight: 500;
  margin-right: 10px;
  cursor: initial;
  text-decoration: none; }
  .nav-module__link___1CU3y div {
    margin-right: 9px; }

.nav-module__link___1CU3y {
  cursor: pointer; }
  .nav-module__link___1CU3y:hover {
    background-color: #473c48;
    color: white; }
