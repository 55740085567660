.slider-module__legend___1l50V {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  margin-top: 6px;
  -ms-flex-pack: justify;
      justify-content: space-between; }

.slider-module__legend-text___3c3XF {
  font-size: 12px;
  font-weight: 700; }
