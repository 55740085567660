.dropdown-module__container___37QDE {
  margin-bottom: 15px;
  position: relative; }

.dropdown-module__dropdown-wrap___lvCpH {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }
  .dropdown-module__dropdown-wrap___lvCpH > *:first-child {
    -ms-flex: 1;
        flex: 1; }

.dropdown-module__delete-icon___3eaon {
  margin-left: 15px; }

.dropdown-module__explain-expand___1io1Z {
  color: #738293;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  margin-top: 8px; }
  .dropdown-module__explain-expand___1io1Z:hover {
    color: #0076ff; }

.dropdown-module__description___3NVCT {
  margin-left: 20px;
  margin-top: 25px; }

.dropdown-module__more-info___189I0 {
  position: relative; }

.dropdown-module__line-container___2wCnc {
  position: absolute;
  top: -33px; }

.dropdown-module__hide-more-info___3HBtA {
  cursor: pointer;
  left: -6px;
  position: absolute;
  top: 46px; }
