.team-module__container___vXwo0 {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  position: relative; }

.team-module__header___3usfH {
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 32px; }

.team-module__row-inputs___2mle0 {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  margin: 0px -5px; }
  .team-module__row-inputs___2mle0 > * {
    margin: 0px 5px;
    -ms-flex: 1;
        flex: 1; }

.team-module__header-item___3xUT1 {
  -ms-flex: 1;
      flex: 1; }
  .team-module__header-item___3xUT1 > * {
    margin-top: 35px; }
  .team-module__header-item___3xUT1 > *:first-child {
    margin-top: 0px; }
  .team-module__header-item___3xUT1:first-child {
    margin-right: 20px; }

.team-module__description___1M5B4 {
  margin-bottom: 30px; }

.team-module__name-input-wrap___1wB0p {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }
  .team-module__name-input-wrap___1wB0p > *:first-child {
    -ms-flex: 1;
        flex: 1; }
  .team-module__name-input-wrap___1wB0p > *:last-child {
    margin-left: 15px; }
