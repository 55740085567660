.example-module__container___3QR5P {
  width: 100%;
  height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center; }

.example-module__go-back___1Pfal {
  color: #2c242c;
  font-size: 14px;
  font-weight: 700;
  position: absolute;
  top: -40px;
  left: 0px;
  cursor: pointer;
  text-decoration: none; }
  .example-module__go-back___1Pfal:hover {
    text-decoration: underline; }

.example-module__form-container___2I93e {
  border-radius: 4px;
  border: 1px solid #f0f0f0;
  background-color: #ffffff;
  width: 90%;
  max-width: 500px;
  padding: 20px;
  position: relative; }

.example-module__img___16nsp {
  position: relative;
  width: 109%;
  position: relative;
  left: -4.5%;
  top: -20px; }

.example-module__headline___3K50I {
  color: #2c242c;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.2px;
  margin-bottom: 35px; }

.example-module__buttons___JTTku {
  margin-bottom: 22px; }

.example-module__button___21MAN {
  box-shadow: 0 8px 14px rgba(0, 118, 255, 0.2);
  border: none;
  border-radius: 4px;
  background-color: #0076ff;
  height: 40px;
  width: 100%;
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  cursor: pointer;
  padding: 0px 15px 0px 15px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-align: center;
      align-items: center;
  outline: none; }
  .example-module__button___21MAN svg, .example-module__button___21MAN path {
    fill: white; }
  .example-module__button___21MAN:hover {
    background-color: #1a84ff; }
  .example-module__button___21MAN:disabled {
    background-color: #cecece;
    box-shadow: 0 8px 14px rgba(140, 140, 140, 0.2); }
