.nav-module__nav-container___2rTED {
  position: relative;
  width: 45px;
  height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  box-shadow: -2px 0 16px rgba(44, 36, 44, 0.08);
  cursor: pointer; }
  .nav-module__nav-container___2rTED:hover {
    color: #0076ff; }

.nav-module__icon___1T1LE {
  position: absolute;
  width: 16px;
  height: 16px;
  background: white;
  border: 5px solid red;
  border-radius: 50%;
  left: -8px;
  bottom: -8px; }

.nav-module__menu___18gR1 {
  position: absolute;
  right: 0;
  top: 0;
  box-shadow: -2px 0 16px rgba(44, 36, 44, 0.08);
  background-color: #ffffff;
  padding: 17px; }

.nav-module__header___o-WHH {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-align: center;
      align-items: center; }
  .nav-module__header___o-WHH div {
    font-size: 14px;
    font-weight: 400; }
    .nav-module__header___o-WHH div:hover {
      color: #0076ff; }
  .nav-module__header___o-WHH p {
    margin-right: 130px;
    color: #a9a9a9;
    font-size: 10px;
    font-weight: 700;
    text-transform: uppercase; }

.nav-module__pages___1ZM_u {
  margin-top: 20px; }
  .nav-module__pages___1ZM_u > div {
    padding-top: 8px;
    padding-bottom: 8px; }
  .nav-module__pages___1ZM_u > div:first-child {
    padding-top: 0px; }
  .nav-module__pages___1ZM_u > div:last-child {
    padding-bottom: 0px; }

.nav-module__page___310QM {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: justify;
      justify-content: space-between;
  cursor: pointer;
  color: #2c242c; }
  .nav-module__page___310QM:hover .nav-module__page-name___3BzYS {
    color: #0076ff; }

.nav-module__page-active___3G86z .nav-module__page-name___3BzYS {
  color: #0076ff; }

.nav-module__page-hidden___19A1u {
  color: #cecece; }

.nav-module__page-name___3BzYS {
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 0.46px;
  line-height: 15px; }

.nav-module__page-view-icons___2sPXg {
  display: -ms-flexbox;
  display: flex;
  font-size: 14px; }
  .nav-module__page-view-icons___2sPXg:hover {
    color: #0076ff; }

.nav-module__name-container___38-Am {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }

.nav-module__page-crown___il2b5 {
  color: #682eff;
  font-size: 12px;
  margin-right: 9px;
  margin-bottom: 2px; }
  .nav-module__page-crown___il2b5:hover {
    color: #7a48ff; }
