.suggest-module__input-container___2ayAp {
  position: relative; }

.suggest-module__suggest-box___2wmYe {
  -ms-flex-align: center;
      align-items: center;
  cursor: pointer;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%); }
  .suggest-module__suggest-box___2wmYe:hover p {
    color: #0076ff; }
  .suggest-module__suggest-box___2wmYe:hover + .suggest-module__input___ZkLEC {
    border: 1px solid #0076ff; }
  .suggest-module__suggest-box___2wmYe:hover + .suggest-module__input___ZkLEC.suggest-module__input-active___7xEfS {
    border: 1px solid #0076ff;
    border-bottom: 0; }

.suggest-module__line___1xPLg {
  background-color: #a9a9a9;
  height: 20px;
  width: 1px; }

.suggest-module__text___1F2g- {
  color: #2c242c;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  padding: 0 13px; }

.suggest-module__input___ZkLEC {
  border: 1px solid #cecece;
  border-radius: 4px;
  color: #2c242c;
  font-size: 14px;
  font-weight: 400;
  height: 40px;
  outline: none;
  padding-left: 12px;
  padding-right: 81px;
  transition: 100ms border;
  width: 100%; }
  .suggest-module__input___ZkLEC:hover {
    border: 1px solid #0076ff; }
  .suggest-module__input___ZkLEC:focus {
    border: 1px solid #0076ff;
    box-shadow: inset 0 0 0 2px rgba(0, 118, 255, 0.2); }

.suggest-module__input-active___7xEfS {
  border: 1px solid #0076ff;
  border-bottom: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }
  .suggest-module__input-active___7xEfS:hover {
    border-bottom: 0; }
  .suggest-module__input-active___7xEfS:focus {
    border-bottom: 0; }

.suggest-module__menu___30doE {
  background: #ffffff;
  border: 1px solid #0076ff;
  border-top: 1px solid #cecece;
  max-height: 250px;
  overflow-y: scroll;
  position: absolute;
  width: 100%;
  z-index: 1;
  padding-top: 10px;
  padding-bottom: 10px; }

.suggest-module__menu-headline___1LHV- {
  color: #a9a9a9;
  font-size: 10px;
  font-weight: 700;
  padding: 11px 13px;
  text-transform: uppercase; }
  .suggest-module__menu-headline___1LHV- > :first-child {
    padding-top: 0; }

.suggest-module__item-container___1SbRS {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: justify;
      justify-content: space-between;
  padding: 13px 13px; }
  .suggest-module__item-container___1SbRS:last-child {
    padding: 13px 13px 0px 13px; }
  .suggest-module__item-container___1SbRS:first-child {
    padding: 0px 13px 13px 13px; }

.suggest-module__item___1VoAk {
  color: #2c242c;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  padding-top: 0; }
  .suggest-module__item___1VoAk:hover {
    color: #0076ff; }
