.font-module__font-selector___3DQrs {
  height: 40px;
  width: 100%;
  border: 1px solid #3d343d;
  background-color: #2c242c;
  border-radius: 4px;
  padding-left: 15px;
  padding-right: 15px;
  outline: none;
  color: white;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: justify;
      justify-content: space-between;
  cursor: pointer; }
  .font-module__font-selector___3DQrs:hover {
    background-color: #3a2f3a; }
  .font-module__font-selector___3DQrs:focus {
    border: 1px solid #4b404b; }
  .font-module__font-selector___3DQrs input {
    border: none;
    outline: none;
    height: 100%;
    background: transparent;
    color: white;
    cursor: pointer;
    width: 100%; }

.font-module__font-selector-active___1M_7O {
  background-color: #2c242c;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom: 0px; }

.font-module__font-list___1wUUA {
  background-color: #2c242c;
  border: 1px solid #3d343d;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  max-height: 250px;
  overflow: auto;
  position: absolute;
  width: 100%;
  z-index: 10000; }

.font-module__font-item___1eaM2 {
  height: 40px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  cursor: pointer;
  padding-left: 15px;
  padding-right: 15px;
  color: white; }
  .font-module__font-item___1eaM2:hover {
    background-color: #3a2f3a; }
