.contact-module__container___27Ap9 {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  position: relative; }

.contact-module__header___jbGXS {
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 32px; }

.contact-module__row-inputs___2SiwJ {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  margin: 0px -5px; }
  .contact-module__row-inputs___2SiwJ > * {
    margin: 0px 5px; }

.contact-module__header-item___jp_IY {
  -ms-flex: 1;
      flex: 1; }
  .contact-module__header-item___jp_IY > * {
    margin-top: 35px; }
  .contact-module__header-item___jp_IY > *:first-child {
    margin-top: 0px; }
  .contact-module__header-item___jp_IY:first-child {
    margin-right: 20px; }

.contact-module__description___qbcEM {
  margin-bottom: 30px; }

.contact-module__delete-icon___Lg0qQ {
  position: absolute;
  right: -25px;
  top: 10px; }
