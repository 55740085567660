.checkbox-module__label___2dcV9 {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  cursor: pointer; }
  .checkbox-module__label___2dcV9 span {
    display: block;
    margin-left: 6px;
    color: #a9a9a9;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none; }
