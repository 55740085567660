.app-module__form-modal___3jekP {
  text-align: center; }
  .app-module__form-modal___3jekP .app-module__emoji___Zl70P {
    font-size: 40px;
    line-height: 62px; }
  .app-module__form-modal___3jekP h2 {
    color: #2c242c;
    font-size: 24px;
    font-weight: 500;
    line-height: 39px;
    margin-bottom: 25px; }
  .app-module__form-modal___3jekP p {
    color: #2c242c;
    font-size: 15px;
    font-weight: 400;
    max-width: 90%;
    margin: 0 auto;
    line-height: 27px; }
  .app-module__form-modal___3jekP a {
    color: #682eff;
    font-weight: 700; }

.app-module__button-modal___3Qt4M {
  color: #0076ff;
  background-color: rgba(0, 118, 255, 0.2);
  font-size: 16px;
  font-weight: 700;
  border-radius: 4px;
  background-color: rgba(0, 118, 255, 0.2);
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 20px;
  padding-bottom: 20px;
  max-width: 260px;
  margin: 0 auto;
  margin-top: 35px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: pointer;
  margin-bottom: 22px; }
  .app-module__button-modal___3Qt4M:hover {
    background-color: rgba(0, 118, 255, 0.3); }
