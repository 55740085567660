.page-module__premium___11VZc {
  display: -ms-flexbox;
  display: flex;
  margin-top: 25px;
  margin-left: 20px;
  border-radius: 4px;
  background-color: #ffffff;
  border: 1px solid #682eff; }

.page-module__headline___1nldh {
  display: -ms-flexbox;
  display: flex; }
  .page-module__headline___1nldh p {
    color: #2c242c;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0.2px; }
  .page-module__headline___1nldh p:last-child {
    color: #682eff; }
  .page-module__headline___1nldh div {
    margin-left: 6px;
    margin-right: 4px;
    color: #682eff; }

.page-module__description___3eu0a {
  padding: 21px 30px;
  -ms-flex: 1;
      flex: 1; }

.page-module__premium-description___2StuP {
  color: #a9a9a9;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  margin-top: 10px; }

.page-module__unlock___29Aqr {
  margin-top: 10px;
  color: #682eff;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  cursor: pointer;
  text-decoration: underline; }
  .page-module__unlock___29Aqr:hover {
    text-decoration: none; }
