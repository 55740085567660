.billing-module__content___2cTVL {
  padding: 32px 50px; }

.billing-module__headline___1h52a {
  color: #2c242c;
  font-size: 22px;
  font-weight: 500;
  line-height: 26px;
  margin-bottom: 30px; }

.billing-module__label___1ckWc {
  color: #2c242c;
  margin-bottom: 11px;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px; }

.billing-module__control___1m2sk {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-align: start;
      align-items: flex-start;
  margin-top: 45px; }

.billing-module__subscribe___3v8rR {
  padding: 14px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  border-radius: 4px;
  border: 1px solid #f0f0f0;
  max-width: 600px;
  width: 100%; }

.billing-module__subscribe-item___1qwR5 .billing-module__label-item___3xVxP {
  color: #a9a9a9;
  font-size: 10px;
  font-weight: 700;
  text-transform: uppercase; }

.billing-module__subscribe-item___1qwR5 .billing-module__text___1L3SE {
  color: #2c242c;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px; }

.billing-module__card____zBse {
  padding: 14px;
  border-radius: 4px;
  border: 1px solid #f0f0f0; }
  .billing-module__card____zBse p:first-child {
    margin-bottom: 8px; }

.billing-module__card-item___3JO5F {
  color: #2c242c;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px; }
  .billing-module__card-item___3JO5F span {
    font-weight: 400; }

.billing-module__card-element___24yiU {
  width: 100%;
  margin-top: 10px;
  margin-left: 15px; }

.billing-module__stripe-element___HdL3h {
  display: block;
  margin: 10px 0 20px 0;
  max-width: 500px;
  padding: 10px 14px;
  font-size: 1em;
  font-family: 'Source Code Pro', monospace;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border: 0;
  outline: 0;
  border-radius: 4px;
  background: white; }

.billing-module__card-button___rGHJL {
  white-space: nowrap;
  border: 0;
  outline: 0;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  padding: 0 14px;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  color: #fff;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  background-color: #0076ff;
  text-decoration: none;
  transition: all 150ms ease;
  cursor: pointer; }
  .billing-module__card-button___rGHJL:hover {
    background-color: #1a84ff; }
