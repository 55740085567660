.template-module__container___3eQQO {
  transform: translateZ(0px); }

.template-module__template___VKzvh {
  width: 50%;
  width: 400px;
  height: calc(100% - 80px);
  will-change: width;
  transform: translateZ(0px); }

.template-module__wrapper___3zuvJ {
  height: 100%;
  overflow: hidden;
  transform: translateZ(0px); }
  .template-module__wrapper___3zuvJ.template-module__template-mobile___1z38A {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
        justify-content: center;
    -ms-flex-line-pack: center;
        align-content: center;
    background: white;
    border-radius: 34px;
    width: 400px;
    margin: 30px auto;
    height: calc(100% - 90px);
    box-shadow: 0 2px 0 rgba(0, 118, 255, 0.2); }
