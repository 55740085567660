.tier-module__tier___29l9b {
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -ms-flex-direction: column;
      flex-direction: column;
  margin-top: 15px; }
  .tier-module__tier___29l9b:last-child {
    border-right: none; }

.tier-module__tier-headline___1OgfJ {
  color: #a9a9a9;
  font-size: 14px;
  font-weight: 500s;
  letter-spacing: 0.3px;
  pointer-events: none;
  text-align: center; }

.tier-module__border___2YBzh {
  margin-right: -1px;
  border-left: 1px solid #e8edf3;
  border-right: 1px solid #e8edf3;
  border-bottom: 1px solid #e8edf3; }

.tier-module__order___3CHGm {
  position: absolute;
  top: -16px;
  color: #a9a9a9;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.26px; }

.tier-module__dragger___gJCiE {
  width: 25px;
  height: 25px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: end;
      align-items: flex-end;
  -ms-flex-pack: center;
      justify-content: center;
  cursor: pointer;
  transform: rotate(90deg); }
  .tier-module__dragger___gJCiE span {
    position: relative;
    top: -5px; }

.tier-module__menu___TkXa1 {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  margin-top: 8px; }

.tier-module__menu-content___3sqpc {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }

.tier-module__line___1ASQE {
  width: 1px;
  border: 1px solid #a7b6c7;
  height: 13px;
  margin-left: 11px;
  margin-right: 11px; }

.tier-module__price___2JSKE {
  color: #d3dbe4;
  font-size: 12px;
  font-weight: 500; }

.tier-module__deleteTier___1bAyx {
  position: absolute;
  box-shadow: 0 7px 12px rgba(33, 34, 34, 0.04);
  background-color: #ffffff;
  width: 120px;
  left: 50%;
  top: 63px;
  transform: translateX(-50%);
  padding: 10px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-direction: column;
      flex-direction: column;
  z-index: 800;
  border: 1px solid #0076ff;
  -o-border-image: linear-gradient(#0076ff, rgba(0, 0, 0, 0)) 1/1px;
     border-image: linear-gradient(#0076ff, rgba(0, 0, 0, 0)) 1/1px; }
  .tier-module__deleteTier___1bAyx:before {
    content: "";
    position: absolute;
    width: 14px;
    height: 14px;
    border-top: 1px solid #0076ff;
    border-left: 1px solid #0076ff;
    top: -8px;
    left: 88px;
    border-radius: 1px;
    background: white;
    transform: rotate(45deg); }

.tier-module__delete-text___1P4N4 {
  color: #000000;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.17px;
  margin: 0;
  margin-bottom: 8px !important; }
