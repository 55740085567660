.add-module__button___3bhKF {
  -ms-flex-align: center;
      align-items: center;
  background-color: #F8F8F8;
  border: 1px solid #cecece;
  border-radius: 4px;
  color: #2c242c;
  cursor: pointer;
  display: -ms-flexbox;
  display: flex;
  height: 30px;
  -ms-flex-pack: center;
      justify-content: center; }
  .add-module__button___3bhKF:hover {
    border: 1px solid #0076ff;
    color: #0076ff; }

.add-module__text-button___1XWSf {
  font-size: 14px;
  font-weight: 400;
  padding: 8px; }
