.label-module__container___2X031 {
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-align: center;
      align-items: center;
  margin-bottom: 8px; }

.label-module__container-premium___3RC2x {
  margin-bottom: 9px; }

.label-module__label___KzG5n {
  color: #a9a9a9;
  font-size: 10px;
  font-weight: 700;
  text-transform: uppercase; }

.label-module__help-text___5pDyT {
  color: rgba(169, 169, 169, 0.5);
  font-size: 10px;
  font-weight: 700;
  margin-bottom: 8px; }

.label-module__premium___2Hc6F {
  border-radius: 4px;
  background-color: #682eff;
  padding: 7px 9px;
  display: -ms-flexbox;
  display: flex;
  color: #ffffff;
  font-size: 10px;
  font-weight: 500;
  text-decoration: underline;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: pointer; }
  .label-module__premium___2Hc6F div {
    margin-right: 6px; }
  .label-module__premium___2Hc6F:hover {
    text-decoration: none; }
