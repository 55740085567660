.new-module__container___x-FqT {
  display: -ms-flexbox;
  display: flex; }

.new-module__form-container___1Q3Sp {
  padding: 31px 20px;
  -ms-flex: 1;
      flex: 1;
  background-color: white;
  min-height: 100vh;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center; }

.new-module__content___2jSpc {
  width: 90%;
  max-width: 460px;
  margin-bottom: 150px; }

.new-module__go-back___UlZ5z {
  color: #2c242c;
  font-size: 14px;
  font-weight: 700;
  text-decoration: none;
  cursor: pointer;
  margin-bottom: 50px;
  display: block; }
  .new-module__go-back___UlZ5z:hover {
    text-decoration: underline; }

.new-module__headline___S2vWq {
  color: #2c242c;
  font-size: 27px;
  font-weight: 700;
  line-height: 32px;
  margin: 0;
  margin-bottom: 30px; }

.new-module__item___2dk6T {
  margin-bottom: 20px; }

.new-module__dropdown___BXNIw {
  margin-bottom: 0px; }
  .new-module__dropdown___BXNIw > p {
    color: #2c242c;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 7px; }

.new-module__control___1tSrW {
  margin-top: 35px; }

.new-module__button___37UH2 {
  box-shadow: 0 8px 14px rgba(0, 118, 255, 0.2);
  border: none;
  border-radius: 4px;
  background-color: #0076ff;
  height: 40px;
  width: 100%;
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  cursor: pointer;
  padding: 0px 15px 0px 15px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-align: center;
      align-items: center;
  outline: none; }
  .new-module__button___37UH2 svg, .new-module__button___37UH2 path {
    fill: white; }
  .new-module__button___37UH2:hover {
    background-color: #1a84ff; }
  .new-module__button___37UH2:disabled {
    background-color: #cecece;
    box-shadow: 0 8px 14px rgba(140, 140, 140, 0.2); }

.new-module__examples___2t6gU {
  background-color: #fafafa;
  padding: 90px 60px;
  height: 100vh;
  overflow-y: scroll; }

.new-module__example-headline___3kMnO {
  color: #2c242c;
  font-size: 14px;
  font-weight: 500; }

.new-module__example-list___1swfV {
  margin-top: 50px; }
  .new-module__example-list___1swfV > div {
    margin-top: 20px; }
  .new-module__example-list___1swfV > div:first-child {
    margin-top: 0px; }
