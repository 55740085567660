.sidebar-module__container___1i0qm {
  display: -ms-flexbox;
  display: flex;
  min-height: 100vh; }

.sidebar-module__sidenav___2aIot {
  width: 55px;
  background-color: #2c242c;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: justify;
      justify-content: space-between; }

.sidebar-module__logo-small___3YtST {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  margin-top: 20px; }

.sidebar-module__navigation___IAU_6 {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column; }

.sidebar-module__navigation-item___2R10J {
  width: 29px;
  height: 29px;
  margin-top: 20px;
  fill: white;
  font-size: 14px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  border-radius: 4px;
  cursor: pointer; }
  .sidebar-module__navigation-item___2R10J svg, .sidebar-module__navigation-item___2R10J path {
    fill: white !important; }
  .sidebar-module__navigation-item___2R10J:hover {
    background-color: #1a84ff; }

.sidebar-module__navigation-item-active___2rvY- {
  background-color: #0076ff; }

.sidebar-module__info___1cRKq {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column; }

.sidebar-module__info-item___1atn3 {
  width: 29px;
  height: 29px;
  font-size: 14px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 16px;
  color: #ffffffa8; }

.sidebar-module__active-info-item___2bqxa {
  color: #0076ff; }

.sidebar-module__info-item-premium___3kPqW {
  color: #682eff; }
  .sidebar-module__info-item-premium___3kPqW:hover {
    color: #7a48ff; }

.sidebar-module__content___1Ecq8 {
  min-width: 200px;
  max-width: 200px;
  background-color: #1e191e;
  padding: 12px;
  padding-top: 15px; }
