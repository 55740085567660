.wording-module__container___1I-1l {
  border: 1px solid #3d343d;
  background-color: #2c242c;
  padding: 7px;
  padding-top: 15px;
  padding-bottom: 15px; }

.wording-module__elements___2WpvB > div {
  margin-top: 15px; }

.wording-module__label___g3GSm {
  color: #a9a9a9;
  font-size: 10px;
  font-weight: 700;
  text-transform: uppercase; }
