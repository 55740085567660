.proposals-module__container___2jNTM {
  width: 100%; }

.proposals-module__headline___3ZePD {
  color: #fff;
  font-size: 22px;
  font-weight: 500;
  line-height: 26px;
  margin: 0px 6px; }

.proposals-module__controls___2oRml {
  margin-top: 18px;
  margin-bottom: 18px;
  display: -ms-flexbox;
  display: flex; }
  .proposals-module__controls___2oRml > *:first-child {
    margin-right: 8px; }

.proposals-module__new-proposal___3uF2n {
  -ms-flex: 1;
      flex: 1;
  border-radius: 4px;
  background-color: #ffffff;
  padding: 9px;
  color: #2c242c;
  font-size: 13px;
  font-weight: 700;
  line-height: 15px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  text-decoration: none; }
  .proposals-module__new-proposal___3uF2n:hover {
    color: #0076ff; }

.proposals-module__list___1CoPR {
  margin-top: 19px; }

.proposals-module__label___krmZS {
  display: -ms-flexbox;
  display: flex;
  margin: 0px 6px; }
  .proposals-module__label___krmZS p {
    opacity: 0.4;
    color: #ffffff;
    font-size: 10px;
    font-weight: 700;
    line-height: 12px;
    text-transform: uppercase; }

.proposals-module__items___2enPb {
  margin-top: 14px; }
  .proposals-module__items___2enPb > * {
    margin-top: 8px; }
  .proposals-module__items___2enPb > *:first-child {
    margin-top: 0px; }

.proposals-module__item___NpBnb {
  display: -ms-flexbox;
  display: flex;
  color: #ffffff;
  font-size: 13px;
  line-height: 15px;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-align: center;
      align-items: center;
  cursor: pointer;
  margin-bottom: 12px;
  padding: 0px 6px;
  text-decoration: none;
  border-radius: 4px;
  position: relative;
  outline: none;
  font-weight: 700;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }
  .proposals-module__item___NpBnb:hover {
    background-color: rgba(255, 255, 255, 0.2); }
  .proposals-module__item___NpBnb p {
    width: 85%;
    padding: 5px 0px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    outline: none; }
  .proposals-module__item___NpBnb .proposals-module__icon___1JH9Y {
    width: 10%;
    font-size: 14px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
        justify-content: center;
    -ms-flex-align: center;
        align-items: center;
    padding: 5px 0px;
    visibility: hidden; }
    .proposals-module__item___NpBnb .proposals-module__icon___1JH9Y:hover {
      color: #0076ff; }

.proposals-module__menu___3tstq {
  position: absolute;
  right: 0px;
  top: 22px;
  z-index: 33; }

.proposals-module__icon-hover___2kYuT {
  visibility: visible !important; }

.proposals-module__item-active___3SNNr {
  background-color: #0076ff; }
  .proposals-module__item-active___3SNNr .proposals-module__icon___1JH9Y:hover {
    color: #2c242c; }
  .proposals-module__item-active___3SNNr:hover {
    background-color: #1a84ff; }

.proposals-module__modal___173jP .proposals-module__text___1ywp_ {
  color: #ff0000;
  line-height: 23px;
  font-weight: 700; }

.proposals-module__button___1V3Y- {
  box-shadow: 0 8px 14px rgba(0, 118, 255, 0.2);
  border: none;
  border-radius: 4px;
  background-color: #0076ff;
  height: 40px;
  width: 100%;
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  cursor: pointer;
  padding: 0px 15px 0px 15px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-align: center;
      align-items: center;
  outline: none; }
  .proposals-module__button___1V3Y- svg, .proposals-module__button___1V3Y- path {
    fill: white; }
  .proposals-module__button___1V3Y-:hover {
    background-color: #1a84ff; }
  .proposals-module__button___1V3Y-:disabled {
    background-color: #cecece;
    box-shadow: 0 8px 14px rgba(140, 140, 140, 0.2); }

.proposals-module__folder-wrap___2Ecmy {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }
  .proposals-module__folder-wrap___2Ecmy .proposals-module__caret___2klcB {
    margin-right: 6px;
    opacity: 0.5; }
  .proposals-module__folder-wrap___2Ecmy .proposals-module__folder___NYJoS {
    margin-right: 6px;
    opacity: 0.5; }

.proposals-module__add-folder___1HNeg {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.11);
  width: 30px;
  height: 30px;
  color: #ffffff;
  font-size: 13px;
  font-weight: 400;
  cursor: pointer; }
  .proposals-module__add-folder___1HNeg:hover {
    background-color: rgba(255, 255, 255, 0.2); }

.proposals-module__folder-input___3vGKG {
  color: #ffffff;
  font-size: 13px;
  line-height: 15px;
  -ms-flex: 1;
      flex: 1;
  font-weight: 700;
  border: none;
  outline: none;
  background: transparent;
  width: 100%; }
