.tier-module__tier-container___2_BRY {
  position: relative;
  -ms-flex: 1;
      flex: 1; }

.tier-module__tier___2jNT4 {
  padding: 30px;
  border-radius: 4px;
  border: 1px solid #2c242c;
  background-color: #ffffff;
  position: relative;
  z-index: 2; }

.tier-module__tier-free___1uJYc {
  border: 1px solid #a9a9a9;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  margin-top: 40px; }
  .tier-module__tier-free___1uJYc > *:nth-child(2) {
    -ms-flex: 1;
        flex: 1; }
  .tier-module__tier-free___1uJYc > *:first-child {
    margin-right: 70px;
    max-width: 310px; }
  .tier-module__tier-free___1uJYc > *:last-child {
    margin-left: 70px; }
  .tier-module__tier-free___1uJYc .tier-module__header___35AtY {
    margin-bottom: 15px; }

.tier-module__tier-active___wZ0TO {
  border: 1px solid #0076ff; }

.tier-module__tier-biz___2WXGx {
  border: 1px solid #322933;
  background-color: #322933; }
  .tier-module__tier-biz___2WXGx .tier-module__name___W-zPt {
    color: #ffffff; }
  .tier-module__tier-biz___2WXGx .tier-module__description___ppcfe {
    color: #ffffff; }
  .tier-module__tier-biz___2WXGx .tier-module__button___2KWXg {
    background-color: #ffffff;
    color: #2c242c; }
    .tier-module__tier-biz___2WXGx .tier-module__button___2KWXg:hover {
      background-color: #ffffff; }
  .tier-module__tier-biz___2WXGx .tier-module__item___xtWf4 .tier-module__checkmark___2w678 {
    color: #ffffff !important; }
  .tier-module__tier-biz___2WXGx .tier-module__item___xtWf4 .tier-module__tier-item-text___ho6H8 {
    color: #ffffff !important; }

.tier-module__header___35AtY {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: justify;
      justify-content: space-between;
  margin-bottom: 5px; }

.tier-module__name___W-zPt {
  color: #2c242c;
  font-size: 22px;
  font-weight: 700; }

.tier-module__price___1eebp {
  color: #2c242c;
  font-size: 26px;
  font-weight: 700; }
  .tier-module__price___1eebp span {
    color: #a9a9a9;
    font-size: 14px;
    font-weight: 500; }

.tier-module__description___ppcfe {
  color: #2c242c;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px; }

.tier-module__items___3kqyf {
  margin-top: 25px; }
  .tier-module__items___3kqyf > div {
    margin-top: 20px; }
  .tier-module__items___3kqyf > div:first-child {
    margin-top: 0px; }

.tier-module__tier-free-items___4Qk9g {
  margin-top: 0px;
  display: grid;
  grid-template-columns: 42% 58%;
  grid-row-gap: 15px; }
  .tier-module__tier-free-items___4Qk9g > div {
    margin-top: 0px; }

.tier-module__item___xtWf4 {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }

.tier-module__item-active___3GyIC .tier-module__checkmark___2w678 {
  color: #0076ff; }

.tier-module__item-active___3GyIC .tier-module__tier-item-text___ho6H8 {
  color: #2c242c; }

.tier-module__control___2zndw {
  margin-top: 22px; }

.tier-module__button___2KWXg {
  border-radius: 4px;
  border: 1px solid #2c242c;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: justify;
      justify-content: space-between;
  padding: 15px;
  cursor: pointer;
  color: #ffffff;
  background-color: #2c242c;
  width: 100%;
  text-decoration: none;
  outline: none; }
  .tier-module__button___2KWXg p {
    font-size: 13px;
    font-weight: 600; }
  .tier-module__button___2KWXg:hover {
    background-color: #3a2f3a; }
  .tier-module__button___2KWXg:disabled {
    cursor: default;
    -ms-flex-pack: center;
        justify-content: center; }

.tier-module__button-favorite___jt4oi {
  border: 1px solid #0076ff;
  background-color: #0076ff; }
  .tier-module__button-favorite___jt4oi:hover {
    background-color: #1a84ff; }

.tier-module__button-active___3Lgcv {
  background-color: rgba(0, 118, 255, 0.2);
  color: #0076ff;
  border: none; }
  .tier-module__button-active___3Lgcv:hover {
    background-color: rgba(0, 118, 255, 0.2); }

.tier-module__free-button___pRXa5 {
  background-color: white;
  color: #2c242c; }
  .tier-module__free-button___pRXa5 > div {
    margin-left: 7px; }
  .tier-module__free-button___pRXa5:disabled {
    cursor: default;
    opacity: 1; }
  .tier-module__free-button___pRXa5:hover {
    background-color: white;
    color: #3a2f3a; }

.tier-module__checkmark___2w678 {
  margin-right: 9px;
  color: #cecece; }

.tier-module__tier-item-text___ho6H8 {
  color: #cecece;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  margin-right: 7px; }

.tier-module__question___1juHq {
  color: #CECECE;
  font-size: 13px;
  font-weight: 400; }

.tier-module__badge___3bGvH {
  position: absolute;
  top: -32px;
  width: 100%;
  height: 35px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  border-radius: 4px;
  background-color: #0076ff;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px; }
  .tier-module__badge___3bGvH p {
    color: #ffffff;
    font-size: 10px;
    font-weight: 700;
    text-transform: uppercase; }

.tier-module__tier-favorite___fBc_F {
  border: 1px solid #0076ff; }

.tier-module__free-tier-headline___3as1_ {
  color: #2c242c;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 13px; }

.tier-module__free-tier-description___1HF97 {
  color: #2c242c;
  font-size: 13px;
  font-weight: 400;
  line-height: 24px; }

.tier-module__free-tier-list___3o86W {
  list-style: none; }
  .tier-module__free-tier-list___3o86W li {
    color: #2c242c;
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center; }
  .tier-module__free-tier-list___3o86W li::before {
    content: "\2022";
    color: #0076ff;
    font-weight: bold;
    display: inline-block;
    margin-right: 10px;
    font-size: 26px;
    line-height: 0;
    margin-bottom: 5px; }

.tier-module__buttons-free-modal___3KF_J {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  margin-right: -25px;
  margin-left: -25px;
  margin-top: 25px; }
  .tier-module__buttons-free-modal___3KF_J .tier-module__button-free-modal___1rpve {
    border-radius: 4px;
    -ms-flex: 1;
        flex: 1;
    display: -ms-flexbox;
    display: flex;
    padding-top: 14px;
    text-align: center;
    height: 44px;
    margin-right: 25px;
    margin-left: 25px;
    -ms-flex-pack: center;
        justify-content: center;
    -ms-flex-line-pack: center;
        align-content: center;
    cursor: pointer; }
  .tier-module__buttons-free-modal___3KF_J div:first-child {
    background-color: #0076ff;
    color: white;
    font-size: 13px;
    font-weight: 700; }
    .tier-module__buttons-free-modal___3KF_J div:first-child:hover {
      background-color: #1a84ff; }
  .tier-module__buttons-free-modal___3KF_J div:last-child {
    border: 1px solid #2c242c;
    color: #2c242c;
    font-size: 13px;
    font-weight: 700; }
    .tier-module__buttons-free-modal___3KF_J div:last-child:hover {
      border: 1px solid #3a2f3a;
      color: #3a2f3a; }

.tier-module__plan___31xw7 {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  margin-top: 15px;
  margin-bottom: -15px; }
  .tier-module__plan___31xw7 .tier-module__label___Fxxq5 {
    color: #cecece;
    font-size: 10px;
    font-weight: 700;
    text-transform: uppercase; }
  .tier-module__plan___31xw7 .tier-module__item___xtWf4 {
    color: #2c242c;
    font-size: 14px;
    font-weight: 700;
    line-height: 24px; }

.tier-module__img-container___yNRgw {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center; }

.tier-module__soon___1dk7f {
  color: #0076ff;
  font-size: 10px;
  line-height: 12px;
  font-weight: 700;
  position: relative;
  left: -3px;
  top: -4px; }

.tier-module__price-dis___3AA5y {
  transform: scale(0.7);
  position: absolute;
  top: -25px;
  left: 12px;
  text-decoration: line-through; }
