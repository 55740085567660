.icon-module__icon___2N_vp {
  cursor: pointer;
  outline: none; }

.icon-module__primary-0___9Sff8 svg, .icon-module__primary-0___9Sff8 path {
  fill: #a9a9a9; }

.icon-module__primary-0___9Sff8:hover svg, .icon-module__primary-0___9Sff8:hover path {
  fill: #0076ff; }

.icon-module__primary-0___9Sff8.icon-module__active___1iNRt svg, .icon-module__primary-0___9Sff8.icon-module__active___1iNRt path {
  fill: #0076ff; }

.icon-module__primary-1___8J6Nr svg, .icon-module__primary-1___8J6Nr path {
  fill: #0076ff; }

.icon-module__primary-1___8J6Nr:hover svg, .icon-module__primary-1___8J6Nr:hover path {
  fill: #66adff; }

.icon-module__primary-1___8J6Nr.icon-module__active___1iNRt svg, .icon-module__primary-1___8J6Nr.icon-module__active___1iNRt path {
  fill: #66adff; }

.icon-module__primary-2___YqLEM svg, .icon-module__primary-2___YqLEM path {
  fill: #2c242c; }

.icon-module__primary-2___YqLEM:hover svg, .icon-module__primary-2___YqLEM:hover path {
  fill: #0076ff; }

.icon-module__primary-2___YqLEM.icon-module__active___1iNRt svg, .icon-module__primary-2___YqLEM.icon-module__active___1iNRt path {
  fill: #0076ff; }

.icon-module__primary-3___aEr4F svg, .icon-module__primary-3___aEr4F path {
  fill: #49e23b; }

.icon-module__primary-3___aEr4F:hover svg, .icon-module__primary-3___aEr4F:hover path {
  fill: #49e23b; }

.icon-module__primary-3___aEr4F.icon-module__active___1iNRt svg, .icon-module__primary-3___aEr4F.icon-module__active___1iNRt path {
  fill: #49e23b; }

.icon-module__primary-4___3rm88 svg, .icon-module__primary-4___3rm88 path {
  fill: #a9a9a9; }

.icon-module__primary-4___3rm88:hover svg, .icon-module__primary-4___3rm88:hover path {
  fill: #ff0000; }

.icon-module__primary-4___3rm88.icon-module__active___1iNRt svg, .icon-module__primary-4___3rm88.icon-module__active___1iNRt path {
  fill: #ff0000; }

.icon-module__primary-5___15stf svg, .icon-module__primary-5___15stf path {
  fill: #707882; }

.icon-module__primary-5___15stf:hover svg, .icon-module__primary-5___15stf:hover path {
  fill: #707882; }

.icon-module__primary-5___15stf.icon-module__active___1iNRt svg, .icon-module__primary-5___15stf.icon-module__active___1iNRt path {
  fill: #707882; }
