.input-module__container___zfVcJ {
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column; }
  .input-module__container___zfVcJ label {
    color: #a9a9a9;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 700;
    margin-bottom: 7px; }
  .input-module__container___zfVcJ input {
    box-shadow: inset 0 0 0 rgba(0, 118, 255, 0.2);
    border-radius: 4px;
    background-color: #ffffff;
    height: 40px;
    padding-left: 12px;
    padding-right: 12px;
    color: #2c242c;
    outline: none;
    border: 1px solid #cecece; }
    .input-module__container___zfVcJ input:focus {
      border: 1px solid #0076ff; }
  .input-module__container___zfVcJ .input-module__disabled___MKFmn {
    background: transparent; }

.input-module__container___zfVcJ.input-module__dark___2tKKo label {
  color: #2c242c; }
