.input-module__container___2dmtQ {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  position: relative; }
  .input-module__container___2dmtQ:focus-within svg, .input-module__container___2dmtQ:focus-within path {
    fill: #0076ff; }

.input-module__input___3x6g6 {
  border: 1px solid #cecece;
  border-radius: 4px;
  color: #2c242c;
  font-size: 14px;
  font-weight: 400;
  height: 40px;
  outline: none;
  padding-left: 12px;
  transition: 100ms border;
  width: 100%; }
  .input-module__input___3x6g6:hover {
    border: 1px solid #0076ff; }
  .input-module__input___3x6g6:focus {
    border: 1px solid #0076ff;
    box-shadow: inset 0 0 0 2px rgba(0, 118, 255, 0.2); }

.input-module__input-padding___3El2Q {
  padding-right: 30px; }

.input-module__error___1E9eJ {
  border: 1px solid #ff0000; }

.input-module__error-text___1az8i {
  bottom: -17px;
  color: #ff0000;
  font-size: 12px;
  font-weight: 400;
  position: absolute; }

.input-module__label-container___A4lwD {
  position: relative;
  width: 100%; }

.input-module__label-block___1cOfN {
  position: absolute;
  top: -15px; }

.input-module__icon___3aarM {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%); }
