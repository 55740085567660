.link-module__container___2vDLk {
  -ms-flex-align: center;
      align-items: center;
  display: -ms-flexbox;
  display: flex;
  position: relative; }
  .link-module__container___2vDLk > div:first-child {
    -ms-flex: 1;
        flex: 1;
    position: relative;
    display: -ms-flexbox;
    display: flex; }
    .link-module__container___2vDLk > div:first-child > div {
      -ms-flex: 1;
          flex: 1; }
    .link-module__container___2vDLk > div:first-child > div:last-child {
      margin-left: 10px; }

.link-module__deleteIcon___2uEoc {
  margin-left: 15px; }

.link-module__more-info___2aaNY {
  margin-right: 30px;
  position: relative; }

.link-module__item___3Q61p {
  margin-left: 20px;
  margin-top: 25px; }

.link-module__line-container___33RHh {
  position: absolute;
  top: -33px; }

.link-module__hide-more-info___2z9Nl {
  cursor: pointer;
  left: -6px;
  position: absolute;
  top: 46px; }

.link-module__explain-expand___2eoyj {
  color: #738293;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  margin-top: 8px; }
  .link-module__explain-expand___2eoyj:hover {
    color: #0076ff; }
