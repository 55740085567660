.period-module__container___3W-kk {
  position: relative; }
  .period-module__container___3W-kk > div:last-child .period-module__item___1L6p7 {
    margin-top: 35px; }

.period-module__deleteContainer___3VlsD {
  -ms-flex-align: center;
      align-items: center;
  cursor: pointer;
  display: -ms-flexbox;
  display: flex;
  left: 85px;
  position: absolute;
  top: -22px; }
  .period-module__deleteContainer___3VlsD:hover {
    color: #0076ff; }
    .period-module__deleteContainer___3VlsD:hover .period-module__delete-text___Z_P3E {
      color: #0076ff; }

.period-module__delete-icon___3X-7N {
  margin-left: 5px;
  margin-right: 2px; }

.period-module__line-delete___3-VaZ {
  background-color: #2c242c;
  height: 1px;
  width: 10px; }

.period-module__delete-text___Z_P3E {
  color: #2c242c;
  font-size: 10px;
  font-weight: 700;
  text-transform: uppercase; }

.period-module__item___1L6p7 {
  -ms-flex-align: center;
      align-items: center;
  display: -ms-flexbox;
  display: flex;
  margin-top: 18px; }
  .period-module__item___1L6p7 > * {
    -ms-flex: 1;
        flex: 1; }

.period-module__date-input___3dDZi {
  -ms-flex-align: center;
      align-items: center;
  background-color: #ffffff;
  border: 1px solid #cecece;
  border-radius: 4px;
  cursor: pointer;
  display: -ms-flexbox;
  display: flex;
  font-size: 14px;
  font-weight: 400;
  height: 40px;
  -ms-flex-pack: justify;
      justify-content: space-between;
  letter-spacing: 1px;
  padding: 0 11px; }
  .period-module__date-input___3dDZi:hover {
    background-color: #f2f2f2; }

.period-module__date___1LgKw {
  position: relative; }

.period-module__date-picker___2e72N {
  position: absolute;
  top: 50px;
  z-index: 10; }

.period-module__line___aDhNb {
  background-color: #a9a9a9;
  height: 1px;
  max-width: 10px; }

.period-module__label___gTDI7 {
  position: absolute;
  top: -18px; }

.CalendarDay__selected {
  background: #0076ff !important;
  border: 1px solid #0076ff !important;
  color: #ffffff !important;
  font-weight: bold; }

.CalendarDay__selected:hover {
  background: #0076ff;
  color: #186a3b; }

.CalendarDay__selected:active {
  background: #0076ff;
  color: #186a3b; }

.CalendarDay__selected_span {
  background: fade(#0076ff, 75%) !important;
  border: 1px solid fade(#0076ff, 75%) !important;
  font-weight: bold; }

.DayPickerKeyboardShortcuts_show__bottomRight {
  visibility: hidden; }
