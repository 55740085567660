.general-module__content___37sf6 {
  padding: 32px 50px; }

.general-module__headline___1EsHS {
  color: #2c242c;
  font-size: 22px;
  font-weight: 500;
  line-height: 26px; }

.general-module__label___2I3t0 {
  color: #2c242c;
  margin-bottom: 11px;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px; }

.general-module__control___1fC1n {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-align: start;
      align-items: flex-start;
  margin-top: 45px; }

.general-module__input___12fbU {
  max-width: 354px; }

.general-module__button-wrap___2Qwnk {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  width: 100%; }
  .general-module__button-wrap___2Qwnk > p {
    margin-left: 11px;
    color: #a9a9a9;
    font-size: 12px;
    font-weight: 500;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none; }
    .general-module__button-wrap___2Qwnk > p > b {
      font-weight: 700;
      color: #2c242c; }
    .general-module__button-wrap___2Qwnk > p span {
      color: #0076ff;
      cursor: pointer; }
      .general-module__button-wrap___2Qwnk > p span:hover {
        text-decoration: underline; }

.general-module__down-inputs___1nLyA {
  margin-top: 25px;
  margin-left: 30px;
  width: 100%; }
  .general-module__down-inputs___1nLyA > div {
    margin-top: 18px; }
  .general-module__down-inputs___1nLyA > div:first-child {
    margin-top: 0px; }

.general-module__org-input___3XjvM {
  max-width: 200px; }
  .general-module__org-input___3XjvM input {
    text-align: right; }

.general-module__org-text___LGWsF {
  max-width: 320px;
  color: #a9a9a9;
  margin-top: 15px;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px; }
  .general-module__org-text___LGWsF b {
    font-weight: 700;
    color: #2c242c; }
  .general-module__org-text___LGWsF span {
    font-weight: 700;
    color: #ff0000; }
