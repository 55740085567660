.loading-module__fullscreen___1vyDz {
  width: 100%;
  height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center; }
  .loading-module__fullscreen___1vyDz svg {
    max-width: 300px; }
