.crm-module__headline___3Kpao {
  color: #2c242c;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 16px; }

.crm-module__list___15RsL {
  color: #a9a9a9;
  font-size: 10px;
  font-weight: 700;
  text-transform: uppercase;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  width: 100%;
  padding-left: 18px;
  padding-right: 18px; }
  .crm-module__list___15RsL > div {
    -ms-flex: 1;
        flex: 1; }

.crm-module__column-small___3qqDS {
  max-width: 10%; }

.crm-module__column-medium___29rWL {
  max-width: 16%; }

.crm-module__column-long___2xqwi {
  max-width: 22%; }
