.radio-module__radio-label___2Tfg3 {
  -ms-flex-align: center;
      align-items: center;
  display: -ms-flexbox;
  display: flex; }

.radio-module__icon___3jG-0 {
  -ms-flex-line-pack: center;
      align-content: center;
  display: -ms-flexbox;
  display: flex;
  margin-right: 8px; }

.radio-module__text___3iE5R {
  color: #2c242c;
  font-size: 14px;
  font-weight: 400; }
  .radio-module__text___3iE5R:hover {
    color: #0076ff;
    cursor: pointer;
    font-weight: 400; }

.radio-module__selected___kvSkc {
  font-weight: 400; }
