.form-module__form___23eks {
  height: 100%;
  width: 100%;
  transform: translateZ(0px);
  background: #fafafa; }

.form-module__elements___Y2cja {
  margin-right: 20px;
  min-height: calc(100vh - 50px - 30px - 50px); }

.form-module__track___2qfc0 {
  width: 3px !important;
  background: none !important;
  top: 33px !important;
  right: 2px !important;
  height: calc(100% - 37px) !important; }
