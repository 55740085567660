.navbar-module__navbar___1Xef5 {
  box-shadow: 0 1px 14px #1a161a;
  background-color: #1a161a;
  border-radius: 0 2px 0 0;
  height: 50px;
  padding: 0px 12px 0px 12px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  position: relative;
  z-index: 1;
  margin-top: 50px; }

.navbar-module__icons___1jLDZ {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  margin: 0px -5px 0px -5px;
  color: white; }
  .navbar-module__icons___1jLDZ > div:hover {
    color: #0076ff; }
  .navbar-module__icons___1jLDZ > * {
    margin-left: 5px;
    margin-right: 5px; }

.navbar-module__icon-active___3WOJA {
  color: #0076ff; }

.navbar-module__buttons___3mxzf {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  margin: 0px -3px 0px -3px; }
  .navbar-module__buttons___3mxzf > * {
    margin: 0px 3px 0px 3px; }
