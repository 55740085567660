.sections-module__sections___1s5GC {
  margin-top: 20px;
  margin-bottom: 100px; }

.sections-module__label___3xK4O {
  color: #a9a9a9;
  font-size: 10px;
  font-weight: 700;
  line-height: 12px;
  text-transform: uppercase;
  margin-left: 15px;
  margin-bottom: 15px; }

.sections-module__drag-container___1F9Xj {
  margin: 0px 15px 10px 15px; }

.sections-module__container___18PN7 {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }

.sections-module__drag___iQQaF {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  margin-right: 6px; }

.sections-module__item___2ODY9 {
  -ms-flex: 1;
      flex: 1;
  height: 40px;
  border-radius: 2px;
  background-color: #3d343d;
  padding: 12px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: justify;
      justify-content: space-between;
  cursor: pointer; }
  .sections-module__item___2ODY9 p {
    color: #ffffff;
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    text-transform: capitalize;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none; }
  .sections-module__item___2ODY9 .sections-module__icon-wrap___1VPfn {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
        justify-content: center;
    -ms-flex-align: center;
        align-items: center; }
  .sections-module__item___2ODY9 .sections-module__icon___1Bnvm {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: center;
        justify-content: center;
    color: #a9a9a9;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px; }
    .sections-module__item___2ODY9 .sections-module__icon___1Bnvm:hover {
      color: #0076ff; }
    .sections-module__item___2ODY9 .sections-module__icon___1Bnvm > div:first-child:hover {
      color: #ff0000; }
  .sections-module__item___2ODY9:hover {
    background-color: #4b404b; }

.sections-module__empty-or-hidden___15_a9 {
  background-color: transparent;
  height: 30px;
  cursor: inherit; }
  .sections-module__empty-or-hidden___15_a9 p {
    color: #a9a9a9; }
  .sections-module__empty-or-hidden___15_a9:hover {
    background-color: transparent; }
    .sections-module__empty-or-hidden___15_a9:hover p {
      color: #c3c3c3; }

.sections-module__item-transition___2Sygp p {
  -ms-flex: 1;
      flex: 1;
  text-align: center; }

.sections-module__section-container___2yA2L {
  background-color: #3d343d;
  margin-left: 13px;
  padding: 15px 12px;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px; }

.sections-module__section-open___2v3v2 .sections-module__item___2ODY9 {
  background-color: #2c242c;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom: 1px solid #3d343d; }
  .sections-module__section-open___2v3v2 .sections-module__item___2ODY9:hover {
    background-color: #3a2f3a; }

.sections-module__section-open___2v3v2 .sections-module__section-container___2yA2L {
  background-color: #2c242c; }

.sections-module__section-element___2Oet2 {
  margin-top: 20px;
  margin-bottom: 20px; }
  .sections-module__section-element___2Oet2:first-child {
    margin-top: 0px; }
  .sections-module__section-element___2Oet2:last-child {
    margin-bottom: 0px; }

.sections-module__add-new___Y84Kg {
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  margin-left: 40px;
  line-height: 24px;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }
  .sections-module__add-new___Y84Kg:hover {
    text-decoration: underline; }
