.publish-module__container___2xXwn {
  position: relative;
  margin-right: 15px; }

.publish-module__publish___22rxT {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-align: center;
      align-items: center;
  border-radius: 2px;
  background-color: #0ecc65;
  color: #ffffff;
  font-size: 13px;
  font-weight: 700;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  border-radius: 4px; }
  .publish-module__publish___22rxT p {
    padding: 9px 10px 9px 10px; }
  .publish-module__publish___22rxT:hover {
    background-color: #0dc260; }
    .publish-module__publish___22rxT:hover .publish-module__publish-icon___1MYrs {
      background-color: #16e073; }

.publish-module__publish-icon___1MYrs {
  background: black;
  width: 28px;
  height: 32px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  background-color: #25ea80; }
  .publish-module__publish-icon___1MYrs > div {
    font-size: 14px;
    font-weight: 400; }

.publish-module__publish-box___3cKhI {
  position: absolute;
  background-color: white;
  right: 0px;
  top: calc(100% + 5px);
  width: 320px;
  box-shadow: 0 2px 16px rgba(44, 36, 44, 0.08);
  border-radius: 4px; }

.publish-module__header___3c8xd {
  color: #cecece;
  font-size: 9px;
  font-weight: 700;
  line-height: 12px;
  border-bottom: 2px solid #f3f3f3;
  padding: 8px 11px;
  text-transform: uppercase; }

.publish-module__button-control___1BB8v {
  margin: 12px; }

.publish-module__button___hZJs6, .publish-module__button-pdf___pw7nE {
  width: 100%;
  color: white;
  border-radius: 4px;
  background-color: #0ecc65;
  font-size: 13px;
  font-weight: 700;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  padding: 9px;
  cursor: pointer; }
  .publish-module__button___hZJs6:hover, .publish-module__button-pdf___pw7nE:hover {
    background-color: #10e471; }

.publish-module__button-pdf___pw7nE {
  background-color: #0076ff;
  margin-top: 11px;
  outline: none;
  border: none; }
  .publish-module__button-pdf___pw7nE:hover {
    background-color: #1a84ff; }

.publish-module__button-pdf-disabled___2QJ1I {
  color: #a9a9a9;
  background-color: #f3f3f3; }
  .publish-module__button-pdf-disabled___2QJ1I:hover {
    background-color: #f3f3f3; }

.publish-module__active-publish___VwpyF {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-align: center;
      align-items: center;
  margin-top: 12px; }

.publish-module__website___2Jzal {
  color: #a9a9a9;
  font-size: 12px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  cursor: pointer; }
  .publish-module__website___2Jzal p {
    margin-left: 6px; }
  .publish-module__website___2Jzal:hover {
    text-decoration: underline; }

.publish-module__last-publish___DTpZi {
  color: #cecece;
  font-size: 12px;
  font-weight: 500;
  font-weight: 500; }

.publish-module__modal-headline___3c3Au {
  color: #2c242c;
  font-size: 18px;
  font-weight: 500;
  margin: 0;
  margin-bottom: 25px; }

.publish-module__modal___1oatz {
  text-align: center; }
  .publish-module__modal___1oatz p {
    color: #2c242c;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 20px; }
  .publish-module__modal___1oatz a {
    color: #0076ff;
    font-weight: 700; }
  .publish-module__modal___1oatz span {
    color: #682eff; }
  .publish-module__modal___1oatz .publish-module__text___3XHmJ {
    margin-bottom: 35px; }

.publish-module__button-modal___2sCzo {
  color: #0076ff;
  font-size: 13px;
  font-weight: 700;
  border-radius: 4px;
  background-color: rgba(0, 118, 255, 0.2);
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  max-width: 249px;
  margin: 0 auto;
  margin-top: 35px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: pointer; }
  .publish-module__button-modal___2sCzo:hover {
    background-color: rgba(0, 118, 255, 0.3); }
