.section-module__section___i5FbA {
  margin-left: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 4px;
  border: 1px solid #f0f0f0;
  background-color: #ffffff;
  padding: 25px 30px; }

.section-module__menu___2rd1f {
  display: -ms-flexbox;
  display: flex; }

.section-module__text___281lI {
  -ms-flex: 1;
      flex: 1; }

.section-module__more-container___3dPRQ {
  position: relative;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.section-module__more___2-12a {
  width: 32px;
  height: 30px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  border-radius: 4px;
  color: #828282;
  cursor: pointer; }
  .section-module__more___2-12a:hover {
    background-color: #eaeaea;
    color: #0076ff; }

.section-module__more-active___3d0Wk {
  background-color: #e2e2e2;
  color: #0076ff; }

.section-module__dropdown___363ZE {
  position: absolute;
  top: calc(30px + 3px);
  right: 0;
  border-radius: 4px;
  border: 1px solid #f0f0f0;
  background: white;
  min-width: 100px; }

.section-module__dropdown-item___21Oiy {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  color: #221b22;
  font-size: 13px;
  font-weight: 500;
  line-height: 15px;
  cursor: pointer;
  padding: 10px; }
  .section-module__dropdown-item___21Oiy p {
    margin-left: 5px; }
  .section-module__dropdown-item___21Oiy:hover {
    background: #eaeaea;
    color: #0076ff; }

.section-module__headline___3nRJd {
  color: #2c242c;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  text-transform: capitalize; }

.section-module__description___1A9U9 {
  margin-top: 14px;
  color: #a9a9a9;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px; }
