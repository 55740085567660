.auth-module__content___2a4Rz {
  max-width: 330px;
  width: 100%; }

.auth-module__content-simple___3bx94 {
  width: 100%;
  height: 100vh;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center; }

.auth-module__headline___3sGkC {
  color: #2c242c;
  font-size: 22px;
  font-weight: 500;
  line-height: 26px;
  margin-bottom: 40px; }

.auth-module__links___3ykbV {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column; }

.auth-module__link-text___fB3YU {
  color: #0076ff;
  text-decoration: underline;
  margin-top: 10px;
  font-size: 12px; }
  .auth-module__link-text___fB3YU:hover {
    text-decoration: none; }

.auth-module__onboarding___1Iaaq {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  height: 100vh;
  width: 100vw;
  background: white; }

.auth-module__onboarding-container___2gHgi {
  max-width: 370px;
  width: 100%;
  margin: 0 auto 100px; }
  .auth-module__onboarding-container___2gHgi h1 {
    margin-bottom: 50px;
    font-size: 22px;
    text-align: center; }

.auth-module__onboarding-flex___1OzMH {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between; }
  .auth-module__onboarding-flex___1OzMH > div {
    margin-right: 10px; }

.auth-module__input-container___3diPU {
  margin-bottom: 25px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  width: 100%; }
  .auth-module__input-container___3diPU:last-child {
    margin-bottom: 0px; }
  .auth-module__input-container___3diPU label {
    color: #a9a9a9;
    font-size: 10px;
    font-weight: 700;
    line-height: 12px;
    margin-bottom: 7px;
    text-transform: uppercase; }
  .auth-module__input-container___3diPU input, .auth-module__input-container___3diPU select {
    height: 40px;
    border-radius: 4px;
    border: 1px solid #CECECE;
    background-color: #ffffff;
    box-shadow: none;
    width: 100%;
    padding-left: 13px;
    outline: none;
    font-size: 14px; }
    .auth-module__input-container___3diPU input:focus, .auth-module__input-container___3diPU select:focus {
      border: 1px solid #0076ff; }
  .auth-module__input-container___3diPU select {
    background: url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path d="M7 10l5 5 5-5z"/><path d="M0 0h24v24H0z" fill="none"/></svg>') 99% 50% no-repeat transparent;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none; }
  .auth-module__input-container___3diPU:focus-within label {
    color: #0076ff; }

.auth-module__buttons___13Trx {
  margin-top: 33px;
  margin-bottom: 15px; }

.auth-module__or___HxWUw {
  color: #a9a9a9;
  margin-top: 16px;
  margin-bottom: 16px;
  font-size: 10px;
  font-weight: 700;
  line-height: 12px;
  text-transform: uppercase;
  text-align: center; }

.auth-module__button___3_JrV {
  margin-bottom: 5px;
  box-shadow: 0 8px 14px rgba(0, 118, 255, 0.2);
  border: none;
  border-radius: 4px;
  background-color: #0076ff;
  height: 40px;
  width: 100%;
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  cursor: pointer;
  padding: 0px 15px 0px 15px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-align: center;
      align-items: center;
  outline: none; }
  .auth-module__button___3_JrV svg, .auth-module__button___3_JrV path {
    fill: white; }
  .auth-module__button___3_JrV:hover {
    background-color: #1a84ff; }
  .auth-module__button___3_JrV:disabled {
    background-color: #cecece;
    box-shadow: 0 8px 14px rgba(140, 140, 140, 0.2); }

.auth-module__eventbrite___wxSjn {
  box-shadow: 0 8px 14px rgba(245, 94, 65, 0.3);
  background-color: #f45c3f;
  text-decoration: none; }
  .auth-module__eventbrite___wxSjn:hover {
    background-color: #f57057; }

.auth-module__news-content___3jJtK {
  max-width: 500px;
  width: 80%; }

.auth-module__content-welcome___2wnp9 {
  max-width: 671px;
  width: 100%;
  position: relative;
  color: white;
  z-index: 2;
  margin: 0 auto; }
  .auth-module__content-welcome___2wnp9 h1 {
    font-size: 44px;
    font-weight: 700;
    line-height: 58px;
    margin-bottom: 24px; }
  .auth-module__content-welcome___2wnp9 p {
    max-width: 500px;
    font-size: 18px;
    font-weight: 300;
    line-height: 31px;
    margin-bottom: 30px;
    text-align: justify; }

.auth-module__headline-news___1n_4X {
  color: #ffffff;
  margin-bottom: 18px;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px; }

.auth-module__card___1IXvN {
  padding: 25px;
  border-radius: 4px;
  background-color: #322933;
  overflow: hidden; }

.auth-module__card-headline___2DzQu {
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px; }

.auth-module__card-description___38A7m {
  opacity: 0.6;
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
  margin-top: 8px;
  width: 100%;
  line-height: 24px; }

.auth-module__sign___ofxi5 {
  position: relative;
  top: -15px; }

.auth-module__jordan___2QRaD {
  position: absolute;
  bottom: 0px;
  right: 40px; }

.auth-module__terms___1NPQZ {
  font-size: 11px;
  margin-top: -4px;
  line-height: 14px;
  margin-bottom: 20px;
  color: #4a4a4a; }
  .auth-module__terms___1NPQZ a {
    color: #0076ff; }

.auth-module__logo-container___3uZ8E {
  position: absolute;
  left: 50px;
  top: 30px; }

.auth-module__onboarding-line-container___2z3x9 {
  position: absolute;
  left: 0;
  bottom: 0px;
  transition: 0.3s width; }
  .auth-module__onboarding-line-container___2z3x9 p {
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    text-transform: uppercase;
    color: #0076FF;
    margin-bottom: 5px;
    margin-right: 5px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: end;
        justify-content: flex-end; }
  .auth-module__onboarding-line-container___2z3x9 > div {
    height: 3px;
    background: #0076FF; }
