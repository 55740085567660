html, body {
  font-family: 'objektiv-mk3'; }

.modal-overlay {
  background-color: rgba(255, 255, 255, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  z-index: 999; }

.modal-container {
  box-shadow: 0 2px 0 rgba(0, 118, 255, 0.2);
  border-radius: 4px;
  border: 1px solid #0076ff;
  background-color: #ffffff;
  outline: none;
  padding: 15px;
  position: relative;
  width: 100%;
  max-height: 100vh; }

.modal-overlay-clean {
  background-color: rgba(44, 36, 44, 0.2);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  z-index: 999; }

.modal-container-clean {
  outline: none;
  padding: 80px;
  position: relative;
  width: 100%;
  max-height: 100vh;
  box-shadow: 0 2px 34px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  background-color: #ffffff; }

 {
  /* Left placement tooltip arrow (arrow will be on right side of tooltip) */
  /* Top placement tooltip arrow (arrow will be on bottom side of tooltip) */
  /* Bottom placement tooltip arrow (arrow will be on top side of tooltip) */ }
  html, body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    background-color: #f7f7f7; }
  html {
    overflow: hidden;
    height: 100%; }
  body {
    height: 100%;
    overflow: auto; }
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box; }
  .tippy-tooltip.honeybee-theme {
    background: white;
    border-radius: 4px;
    box-shadow: 0 2px 16px rgba(44, 36, 44, 0.08);
    padding: 10px;
    font-weight: 400;
    font-size: 14px;
    color: #2c242c; }
  .tippy-popper[x-placement^='right'] .tippy-tooltip.honeybee-theme [x-arrow] {
    border-right: 7px solid #ffffff; }
  .tippy-popper[x-placement^='left'] .tippy-tooltip.honeybee-theme [x-arrow] {
    border-left: 7px solid #ffffff; }
  .tippy-popper[x-placement^=top] .tippy-tooltip.honeybee-theme [x-arrow] {
    border-top: 7px solid #ffffff; }
  .tippy-popper[x-placement^=bottom] .tippy-tooltip.honeybee-theme [x-arrow] {
    border-bottom: 7px solid #ffffff; }
  .tippy-tooltip-content {
    max-width: 200px;
    text-align: left;
    line-height: 1.4; }
    .tippy-tooltip-content p {
      margin-top: 6px; }
      .tippy-tooltip-content p:first-child {
        margin-top: 0px; }

.pac-container {
  position: absolute;
  top: 10px;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  background-color: #ffffff; }

.pac-item {
  font-size: 14px;
  padding-top: 5px;
  padding-bottom: 5px; }

.Toastify__toast--success {
  background: #0076ff;
  border-radius: 4px;
  font-weight: bold;
  font-size: 14px;
  text-align: center; }

.Toastify__toast--error {
  font-weight: bold;
  font-size: 14px;
  text-align: center; }

.Toastify__toast--info {
  background: #fff;
  border-radius: 4px;
  color: black;
  font-weight: bold;
  font-size: 14px;
  text-align: center; }

::-webkit-input-placeholder {
  color: #a9a9a9; }

:-moz-placeholder {
  color: #a9a9a9; }

::-moz-placeholder {
  color: #a9a9a9; }

:-ms-input-placeholder {
  color: #a9a9a9; }

.navigation-module__container___c74z8 {
  height: 58px;
  border: 1px solid #f0f0f0;
  background-color: #ffffff;
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-align: center;
      align-items: center;
  padding: 0px 15px; }

.navigation-module__autosave___1OaLy {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  color: #cecece;
  font-size: 12px;
  font-weight: 700;
  line-height: 24px; }
  .navigation-module__autosave___1OaLy > div {
    margin-right: 6px; }

.navigation-module__button___3o8BU {
  outline: none;
  border: none;
  height: 30px;
  padding: 5px 11px;
  border-radius: 4px;
  background-color: rgba(0, 85, 244, 0.15);
  color: #0076ff;
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 0.14px;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  line-height: 18px;
  display: -ms-flexbox;
  display: flex; }
  .navigation-module__button___3o8BU svg, .navigation-module__button___3o8BU path {
    margin-right: 7px;
    fill: #0076ff !important; }
  .navigation-module__button___3o8BU:last-child svg, .navigation-module__button___3o8BU:last-child path {
    margin-right: 0px;
    margin-left: 7px; }
  .navigation-module__button___3o8BU:hover {
    background-color: rgba(0, 85, 244, 0.05); }

.navigation-module__forward___1nbmm {
  background-color: #0076ff;
  color: white;
  cursor: pointer;
  box-shadow: 0 8px 14px rgba(0, 118, 255, 0.2); }
  .navigation-module__forward___1nbmm svg, .navigation-module__forward___1nbmm path {
    margin-right: 7px;
    fill: white !important; }
  .navigation-module__forward___1nbmm:hover {
    background-color: rgba(0, 118, 255, 0.9); }

.navigation-module__inactive___34ueL {
  visibility: hidden; }
