.spillter-module__instance-container___3_olp {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin: -15px; }
  .spillter-module__instance-container___3_olp > * {
    -ms-flex: 1;
        flex: 1;
    min-width: calc(50% - 30px);
    margin: 15px; }

.spillter-module__splitter-input-container___2aOzq {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: justify;
      justify-content: space-between;
  position: relative;
  z-index: 3; }
  .spillter-module__splitter-input-container___2aOzq > * {
    -ms-flex: 1;
        flex: 1; }

.spillter-module__iconWrap___1t30R {
  max-width: 50px;
  margin-left: 15px;
  margin-right: 2px; }

.spillter-module__add-category___2vWdM {
  position: relative;
  margin-top: 14px;
  cursor: pointer;
  z-index: 2; }
  .spillter-module__add-category___2vWdM p {
    color: #2c242c;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    margin-left: 28px; }
    .spillter-module__add-category___2vWdM p:hover {
      color: #0076ff; }

.spillter-module__arrow___2dYl- {
  position: absolute;
  top: -27px;
  z-index: -1;
  cursor: pointer;
  z-index: 1; }

.spillter-module__arrow-item___3_O60 {
  position: absolute;
  z-index: -1;
  top: -20px;
  cursor: pointer;
  z-index: 1; }

.spillter-module__item___otqUH {
  display: -ms-flexbox;
  display: flex;
  margin-top: 8px; }

.spillter-module__item-delete___LVzkM {
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -ms-flex-align: center;
      align-items: center;
  margin-right: 5px; }

.spillter-module__item-delete-icon___27YEY {
  position: relative;
  left: -91%;
  transform: translateX(50%);
  cursor: pointer;
  z-index: 2; }

.spillter-module__item-percent___305Cp {
  max-width: 60px;
  margin-left: 7px; }

.spillter-module__item-text___16Nsa {
  -ms-flex: 1;
      flex: 1; }
