.account-module__account___3mkHu {
  display: -ms-flexbox;
  display: flex; }

.account-module__content___bdXGI {
  -ms-flex: 1;
      flex: 1; }

.account-module__content-white___2mMKM {
  background: white;
  overflow-y: scroll;
  height: 100vh; }
