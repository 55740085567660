.metric-module__element___3zzeQ {
  margin-top: 33px; }

.metric-module__container___1l357 {
  -ms-flex-align: center;
      align-items: center;
  display: -ms-flexbox;
  display: flex;
  margin: 0px -5px;
  -ms-flex: 1;
      flex: 1; }
  .metric-module__container___1l357 > div {
    -ms-flex: 1;
        flex: 1;
    position: relative;
    margin: 0px 5px; }

.metric-module__metric-value___1-gVc {
  max-width: 200px; }

.metric-module__metric-block___2lhUd {
  position: absolute;
  top: -17px; }

.metric-module__metric-instance___bKnHu {
  margin-top: 10px;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }

.metric-module__delete-icon___3Jjnp {
  cursor: pointer;
  margin-left: 15px; }

.metric-module__add-instance___2urfR {
  margin-top: 30px; }
