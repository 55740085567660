.card-module__card___1tAqi {
  border-radius: 4px;
  border: 1px solid #f0f0f0;
  background-color: #ffffff;
  padding: 15px;
  height: 115px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column; }

.card-module__headline___2SKSg {
  display: -ms-flexbox;
  display: flex;
  color: #cecece;
  font-size: 10px;
  font-weight: 700;
  text-transform: uppercase;
  -ms-flex-align: center;
      align-items: center; }
  .card-module__headline___2SKSg p {
    margin-left: 5px; }

.card-module__value-container___Av8QR {
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 1;
      flex: 1;
  width: 100%;
  height: 100%;
  -ms-flex-align: center;
      align-items: center; }

.card-module__value___1z26A {
  color: #2c242c;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: -1.07px; }
  .card-module__value___1z26A span {
    font-size: 18px;
    font-weight: 500;
    margin-left: 3px; }

.card-module__card-highlight___3qSW5 {
  border-top: 2px solid #0076ff; }
  .card-module__card-highlight___3qSW5 .card-module__value___1z26A {
    color: #0076ff; }
    .card-module__card-highlight___3qSW5 .card-module__value___1z26A span {
      color: #2c242c; }
