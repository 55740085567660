.file-module__container___am1Nt {
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  position: relative;
  width: 100%; }

.file-module__picker___GaFXu {
  -ms-flex-align: center;
      align-items: center;
  background-color: #F8F8F8;
  border: 1px dashed #CECECE;
  border-radius: 4px;
  cursor: pointer;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -ms-flex-pack: center;
      justify-content: center;
  position: relative;
  width: 100%;
  overflow: hidden; }
  .file-module__picker___GaFXu:hover {
    background-color: #f3f3f3; }
    .file-module__picker___GaFXu:hover svg, .file-module__picker___GaFXu:hover path {
      fill: #0076ff; }

.file-module__theme-dark___1KRg4 .file-module__picker___GaFXu {
  background-color: #2c242c;
  border: 1px dashed #3d343d; }
  .file-module__theme-dark___1KRg4 .file-module__picker___GaFXu:hover {
    background-color: #453b45; }

.file-module__text___1eqii {
  color: #a9a9a9;
  font-size: 14px;
  font-weight: 400;
  max-width: 160px;
  text-align: center; }
  .file-module__text___1eqii span {
    color: #0076ff;
    text-decoration: underline; }

.file-module__upload-change-text___2g_zs {
  color: #0076ff; }

.file-module__image___2c2_b {
  height: 100%;
  left: 0;
  -o-object-fit: cover;
     object-fit: cover;
  opacity: 0.2;
  position: absolute;
  top: 0;
  width: 100%; }

.file-module__image-no-opacity___1iuRM {
  opacity: 1; }

.file-module__content___1VI8I {
  width: 100%;
  height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-direction: column;
      flex-direction: column; }

.file-module__opacity___3eVz1 {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #f8f8f88c; }

.file-module__file-name___3ws3a {
  bottom: 10px;
  color: #a9a9a9;
  font-size: 14px;
  font-weight: 400;
  left: 10px;
  position: absolute; }

.file-module__close___379PA {
  cursor: pointer;
  position: absolute;
  right: 7px;
  top: 7px; }
  .file-module__close___379PA:hover svg, .file-module__close___379PA:hover path {
    fill: #0076ff; }

.file-module__label-block___292cB {
  position: absolute;
  top: -20px; }

.file-module__content___1VI8I {
  -ms-flex-align: center;
      align-items: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column; }
