.builder-module__container___1Rwcx {
  width: 100vw;
  height: 100vh;
  overflow: hidden; }

.builder-module__content___17cE4 {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  float: left;
  height: 100%;
  will-change: transform; }

.builder-module__form___3n1Lt {
  width: 50%;
  height: 100%;
  width: 1000px;
  will-change: width;
  transform: translateZ(0px); }

.builder-module__template___3v8gz {
  width: 50%;
  height: 100%;
  width: 400px;
  will-change: width;
  transform: translateZ(0px); }

.builder-module__customization___3QKbp {
  height: 100%;
  width: 350px;
  will-change: width;
  transform: translateZ(0px); }

.builder-module__overflow___23UPu {
  width: 20px;
  background: #2c242c;
  height: 100%; }
