.finance-module__container___2j1z1 {
  border: 1px solid #cecece;
  border-radius: 4px;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.05);
  display: -ms-flexbox;
  display: flex;
  background: #ffffff; }

.finance-module__finance-table___2_6vq {
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 1;
      flex: 1;
  -ms-flex-direction: column;
      flex-direction: column; }
  .finance-module__finance-table___2_6vq:first-child {
    border-right: 1px solid #cecece; }
  .finance-module__finance-table___2_6vq:last-child {
    border-left: 1px solid #cecece; }

.finance-module__header___gbTj- {
  background-color: #F8F8F8;
  border-bottom: 1px solid #cecece;
  padding: 9px;
  padding-left: 15px; }
  .finance-module__header___gbTj- p {
    color: #a9a9a9;
    font-size: 10px;
    font-weight: 700;
    text-transform: uppercase; }

.finance-module__items___2ITgd {
  -ms-flex-align: center;
      align-items: center;
  -ms-flex: 1;
      flex: 1;
  max-height: 700px;
  min-height: 200px;
  overflow-y: scroll; }

.finance-module__item___2DDvu {
  display: -ms-flexbox;
  display: flex; }
  .finance-module__item___2DDvu > div {
    -ms-flex-align: center;
        align-items: center;
    display: -ms-flexbox;
    display: flex; }

.finance-module__deleteIcon___1srfd {
  border-bottom: 1px solid #E7E7E7;
  cursor: pointer;
  -ms-flex-pack: center;
      justify-content: center;
  width: 30px; }
  .finance-module__deleteIcon___1srfd:hover * {
    fill: #0076ff; }

.finance-module__input___dQg2E {
  border: 0;
  border: 1px solid transparent;
  border-bottom: 1px solid #E7E7E7;
  border-radius: 0;
  border-right: 1px solid #E7E7E7; }

.finance-module__label___1mh9F {
  -ms-flex: 1;
      flex: 1; }
  .finance-module__label___1mh9F > * {
    width: 100%; }

.finance-module__currency___UZTAr {
  max-width: 100px; }

.finance-module__add-new___3wnZ- {
  color: #2c242c;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 10px;
  margin-top: 10px;
  padding-left: 15px; }
  .finance-module__add-new___3wnZ-:hover {
    color: #0076ff; }

.finance-module__footer___15CFQ {
  -ms-flex-item-align: center;
      align-self: center;
  background-color: #F8F8F8;
  border-top: 1px solid #cecece;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  padding: 10px 15px;
  width: 100%; }

.finance-module__footer-explain___E_8jE {
  color: #2c242c;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px; }

.finance-module__footer-amount___JWKml {
  color: #E49D0B;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 25px; }
