.pages-module__container___2xzmX {
  max-height: 50px;
  height: auto;
  box-shadow: 0 2px 16px rgba(44, 36, 44, 0.08);
  border-radius: 0 2px 0 0;
  background-color: #ffffff;
  display: -ms-flexbox;
  display: flex;
  position: sticky;
  position: -webkit-sticky;
  top: 50px;
  z-index: 20;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  margin-top: 50px; }

.pages-module__icon-container___2nI_z {
  width: 20px;
  height: auto;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  font-size: 10px;
  border-right: 1px solid #f1f1f2;
  cursor: pointer; }
  .pages-module__icon-container___2nI_z:hover svg, .pages-module__icon-container___2nI_z:hover path {
    fill: #0076ff; }

.pages-module__pages___H1nQa {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  margin-right: 10px;
  width: 100%; }

.pages-module__scroll-bar___2iMny {
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 50px;
  top: 4px; }

.pages-module__content___2CFrZ {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }
  .pages-module__content___2CFrZ > div:first-child {
    padding-left: 20px; }
  .pages-module__content___2CFrZ > div:last-child {
    padding-right: 20px; }

.pages-module__track___1Z0tv {
  left: 0px !important;
  height: 4px !important;
  width: calc(100% - 8px) !important;
  background: none !important;
  opacity: 0;
  transition: 0.1s opacity ease-in-out; }

.pages-module__active-hover___3Sp65 {
  transition: 0.1s opacity ease-in-out;
  opacity: 1; }

.pages-module__container-expanded___1p8DT {
  max-height: 350px;
  transition: 0.6s all ease-in-out;
  overflow: hidden; }
  .pages-module__container-expanded___1p8DT .pages-module__icon-container___2nI_z {
    transform: rotate(180deg);
    transition: 0.2s transform ease-in-out;
    -ms-flex-align: center;
        align-items: center; }

.pages-module__expanded-pages___35hNH {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-align: center;
      align-items: center;
  margin-top: 8px;
  margin-left: 11px; }

.pages-module__line___AoLk1 {
  min-width: 100px;
  height: 1px;
  background: #2c242c;
  margin-left: 20px;
  margin-right: 28px;
  -ms-flex: 1;
      flex: 1; }
