.done-module__container___3Na5p {
  background-color: #ffffff;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center; }

.done-module__content___1G0iR {
  text-align: center; }
  .done-module__content___1G0iR h1 {
    color: #2c242c;
    margin: 0;
    font-size: 27px;
    font-weight: 700;
    margin-bottom: 23px; }

.done-module__text___e1vLt {
  max-width: 450px;
  color: #2c242c;
  margin-bottom: 50px;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px; }

.done-module__link___1OGib {
  max-width: 240px;
  margin: 0 auto;
  height: 30px;
  border-radius: 4px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-align: center;
      align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  background-color: rgba(0, 118, 255, 0.15);
  text-decoration: none;
  color: #0076ff;
  font-size: 13px;
  font-weight: 700;
  cursor: pointer; }
  .done-module__link___1OGib:hover {
    background-color: rgba(0, 118, 255, 0.25); }
