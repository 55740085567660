.website-module__instance___1QLqG {
  margin-bottom: 15px; }
  .website-module__instance___1QLqG:first-child {
    margin-top: 30px; }

.website-module__container___alG81 {
  -ms-flex-align: center;
      align-items: center;
  display: -ms-flexbox;
  display: flex;
  position: relative; }
  .website-module__container___alG81 > div:first-child {
    -ms-flex: 1;
        flex: 1;
    position: relative;
    display: -ms-flexbox;
    display: flex; }
    .website-module__container___alG81 > div:first-child > div {
      -ms-flex: 1;
          flex: 1; }
    .website-module__container___alG81 > div:first-child > div:last-child {
      margin-left: 10px; }

.website-module__deleteIcon___2tPLQ {
  margin-left: 15px; }

.website-module__more-info___3QfYl {
  margin-right: 30px;
  position: relative; }

.website-module__item___3snfU {
  margin-left: 20px;
  margin-top: 25px; }

.website-module__line-container___1-x7c {
  position: absolute;
  top: -33px; }

.website-module__hide-more-info___13DTL {
  cursor: pointer;
  left: -6px;
  position: absolute;
  top: 46px; }

.website-module__explain-expand___3egZS {
  color: #738293;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  margin-top: 8px; }
  .website-module__explain-expand___3egZS:hover {
    color: #0076ff; }
