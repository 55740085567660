.venue-module__container___1P2Bo {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  position: relative; }

.venue-module__inputs___aLOoX {
  position: relative; }
  .venue-module__inputs___aLOoX > *:last-child:not(:only-child) {
    margin-top: 30px; }

.venue-module__places-container___74EY_ {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-align: center;
      align-items: center; }
  .venue-module__places-container___74EY_ > *:first-child {
    -ms-flex: 1;
        flex: 1; }

.venue-module__delete-icon___2f1ma {
  margin-left: 15px;
  margin-top: 14px; }

.venue-module__content-container___2GVdA {
  margin-top: 20px;
  height: 300px;
  margin-bottom: 20px; }

.venue-module__event-details___31vQm {
  display: -ms-flexbox;
  display: flex; }

.venue-module__dates___1qUnm {
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 1;
      flex: 1;
  -ms-flex-direction: column;
      flex-direction: column;
  margin-right: 17px; }

.venue-module__picture-container___1Om6u {
  -ms-flex: 1;
      flex: 1;
  margin-left: 17px;
  margin-top: 18px; }

.venue-module__add-new___2HS0L {
  color: #2c242c;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  margin-top: 15px;
  cursor: pointer; }
  .venue-module__add-new___2HS0L:hover {
    text-decoration: underline; }

.venue-module__addition-date___1YZi3 {
  margin-top: 30px; }

.venue-module__picture-description___2NYoY {
  margin-top: 15px; }

.venue-module__picture___1R979 {
  height: 150px; }
