.contact-module__contact-label___31AzM {
  margin-top: 12px; }
  .contact-module__contact-label___31AzM:first-child {
    margin-top: 0px; }
  .contact-module__contact-label___31AzM .contact-module__label___1pSpQ {
    color: rgba(169, 169, 169, 0.5);
    font-size: 10px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 6px; }
  .contact-module__contact-label___31AzM input {
    height: 45px;
    width: 100%;
    border: 1px solid #3d343d;
    background-color: #2c242c;
    border-radius: 4px;
    padding-left: 15px;
    outline: none;
    color: white; }
    .contact-module__contact-label___31AzM input:focus {
      border: 1px solid #4b404b; }
