.media-module__explain-expand___39dTa {
  color: #738293;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  margin-top: 8px; }
  .media-module__explain-expand___39dTa:hover {
    color: #0076ff; }

.media-module__description___1lI7g {
  margin-left: 20px;
  margin-top: 25px; }

.media-module__more-info___vehLo {
  position: relative; }

.media-module__line-container___1fAF5 {
  position: absolute;
  top: -33px; }

.media-module__hide-more-info___2OqTa {
  cursor: pointer;
  left: -6px;
  position: absolute;
  top: 46px; }
