.example-module__container___2vcJW {
  max-width: 234px;
  min-height: 167px;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #f0f0f0;
  background-color: #ffffff;
  padding: 20px;
  position: relative; }

.example-module__img___27RWj {
  position: absolute;
  top: 0;
  right: 0px; }

.example-module__headline___2fIG7 {
  margin-top: 86px;
  color: #2c242c;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.2px; }

.example-module__buttons___2lVRh {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  margin-top: 11px; }
  .example-module__buttons___2lVRh a:last-child {
    margin-left: 10px; }

.example-module__button___17AmO {
  border-radius: 4px;
  background-color: rgba(0, 118, 255, 0.15);
  display: -ms-inline-flexbox;
  display: inline-flex;
  padding: 10px 12px;
  padding-top: 8px;
  color: #0076ff;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }
  .example-module__button___17AmO > div {
    margin-right: 6px; }
  .example-module__button___17AmO:hover {
    background-color: rgba(0, 118, 255, 0.25); }

.example-module__preview___3W3_H {
  color: #0076ff;
  font-size: 14px;
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer; }
  .example-module__preview___3W3_H:hover {
    text-decoration: none; }
