.social-module__container___3iFwX {
  position: relative; }

.social-module__button___27E8Q {
  -ms-flex-align: center;
      align-items: center;
  background-color: #F8F8F8;
  border: 1px solid #CECECE;
  border-radius: 4px;
  cursor: pointer;
  display: -ms-flexbox;
  display: flex;
  height: 40px;
  -ms-flex-pack: justify;
      justify-content: space-between;
  padding: 0 15px;
  position: relative;
  width: 100%; }
  .social-module__button___27E8Q p {
    color: #2c242c;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px; }
  .social-module__button___27E8Q:hover {
    background-color: #ebebeb; }

.social-module__labelBlock___1-oBx {
  position: absolute;
  top: -15px; }

.social-module__modal-header___nbvNJ {
  color: #2c242c;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 30px; }

.social-module__add-instance___2-8gw {
  margin-bottom: 50px;
  margin-right: 30px;
  margin-top: 25px; }

.social-module__links___23I-M > * {
  margin-bottom: 15px; }

.social-module__button-save___3r8HP {
  -ms-flex-align: center;
      align-items: center;
  background-color: #cce4ff;
  border-radius: 4px;
  bottom: 15px;
  color: #0076ff;
  cursor: pointer;
  display: -ms-flexbox;
  display: flex;
  font-size: 14px;
  font-weight: 700;
  height: 31px;
  -ms-flex-pack: center;
      justify-content: center;
  letter-spacing: 0.16px;
  position: absolute;
  right: 15px;
  text-align: center;
  width: 109px; }
  .social-module__button-save___3r8HP:hover {
    background-color: #b3d7ff; }
