html, body {
  font-family: 'objektiv-mk3'; }

.modal-overlay {
  background-color: rgba(255, 255, 255, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  z-index: 999; }

.modal-container {
  box-shadow: 0 2px 0 rgba(0, 118, 255, 0.2);
  border-radius: 4px;
  border: 1px solid #0076ff;
  background-color: #ffffff;
  outline: none;
  padding: 15px;
  position: relative;
  width: 100%;
  max-height: 100vh; }

.modal-overlay-clean {
  background-color: rgba(44, 36, 44, 0.2);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  z-index: 999; }

.modal-container-clean {
  outline: none;
  padding: 80px;
  position: relative;
  width: 100%;
  max-height: 100vh;
  box-shadow: 0 2px 34px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  background-color: #ffffff; }

 {
  /* Left placement tooltip arrow (arrow will be on right side of tooltip) */
  /* Top placement tooltip arrow (arrow will be on bottom side of tooltip) */
  /* Bottom placement tooltip arrow (arrow will be on top side of tooltip) */ }
  html, body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    background-color: #f7f7f7; }
  html {
    overflow: hidden;
    height: 100%; }
  body {
    height: 100%;
    overflow: auto; }
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box; }
  .tippy-tooltip.honeybee-theme {
    background: white;
    border-radius: 4px;
    box-shadow: 0 2px 16px rgba(44, 36, 44, 0.08);
    padding: 10px;
    font-weight: 400;
    font-size: 14px;
    color: #2c242c; }
  .tippy-popper[x-placement^='right'] .tippy-tooltip.honeybee-theme [x-arrow] {
    border-right: 7px solid #ffffff; }
  .tippy-popper[x-placement^='left'] .tippy-tooltip.honeybee-theme [x-arrow] {
    border-left: 7px solid #ffffff; }
  .tippy-popper[x-placement^=top] .tippy-tooltip.honeybee-theme [x-arrow] {
    border-top: 7px solid #ffffff; }
  .tippy-popper[x-placement^=bottom] .tippy-tooltip.honeybee-theme [x-arrow] {
    border-bottom: 7px solid #ffffff; }
  .tippy-tooltip-content {
    max-width: 200px;
    text-align: left;
    line-height: 1.4; }
    .tippy-tooltip-content p {
      margin-top: 6px; }
      .tippy-tooltip-content p:first-child {
        margin-top: 0px; }

.pac-container {
  position: absolute;
  top: 10px;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  background-color: #ffffff; }

.pac-item {
  font-size: 14px;
  padding-top: 5px;
  padding-bottom: 5px; }

.Toastify__toast--success {
  background: #0076ff;
  border-radius: 4px;
  font-weight: bold;
  font-size: 14px;
  text-align: center; }

.Toastify__toast--error {
  font-weight: bold;
  font-size: 14px;
  text-align: center; }

.Toastify__toast--info {
  background: #fff;
  border-radius: 4px;
  color: black;
  font-weight: bold;
  font-size: 14px;
  text-align: center; }

::-webkit-input-placeholder {
  color: #a9a9a9; }

:-moz-placeholder {
  color: #a9a9a9; }

::-moz-placeholder {
  color: #a9a9a9; }

:-ms-input-placeholder {
  color: #a9a9a9; }
