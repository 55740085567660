.proposal-module__container___3v1T7 {
  padding: 31px 20px;
  background-color: #fafafa;
  min-height: 100vh;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column; }

.proposal-module__content___3EeV2 {
  margin-top: 30px; }

.proposal-module__header___1OvP6 {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-align: center;
      align-items: center; }

.proposal-module__headline___1eFkM {
  color: #2c242c;
  font-size: 22px;
  font-weight: 500;
  line-height: 26px; }

.proposal-module__cards___3tzHK {
  display: -ms-flexbox;
  display: flex;
  margin: -15px;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap; }
  .proposal-module__cards___3tzHK > div {
    margin: 15px;
    -ms-flex: 1;
        flex: 1;
    max-width: 300px; }

.proposal-module__image___3-Q2m {
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 1;
      flex: 1;
  margin-top: 30px;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  position: relative; }
  .proposal-module__image___3-Q2m > div {
    -ms-flex-positive: 1;
        flex-grow: 1; }

.proposal-module__image-proposal___547Q5 {
  max-width: 560px;
  position: relative;
  z-index: 2;
  text-align: center; }
  .proposal-module__image-proposal___547Q5 h2 {
    color: #2c242c;
    font-size: 22px;
    font-weight: 500;
    line-height: 26px; }
  .proposal-module__image-proposal___547Q5 p {
    color: #a9a9a9;
    font-size: 13px;
    font-weight: 400;
    line-height: 24px;
    margin-top: 13px; }
  .proposal-module__image-proposal___547Q5 .proposal-module__small-text___3vqo2 {
    color: #cecece;
    font-size: 10px;
    font-weight: 700;
    line-height: 12px;
    max-width: 250px;
    margin: 0 auto;
    margin-top: 13px; }

.proposal-module__image-element___Ysh82 {
  position: absolute;
  width: 100%;
  height: 100%; }

.proposal-module__links___1TbT4 {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }

.proposal-module__published-link___3vz7J {
  height: 40px;
  border-radius: 4px;
  background-color: rgba(44, 36, 44, 0.05);
  color: #2c242c;
  font-size: 14px;
  font-weight: 700;
  line-height: 15px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  margin-right: 16px;
  padding: 0px 11px;
  cursor: pointer; }
  .proposal-module__published-link___3vz7J:hover {
    background-color: rgba(44, 36, 44, 0.02); }
  .proposal-module__published-link___3vz7J p {
    max-width: 240px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  .proposal-module__published-link___3vz7J span {
    opacity: 0.5;
    font-weight: 400; }
  .proposal-module__published-link___3vz7J > div {
    margin-left: 10px; }

.proposal-module__crm___2kdRI {
  margin-top: 31px; }
