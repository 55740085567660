.button-module__button___3GaBQ {
  border-radius: 2px;
  background-color: rgba(44, 36, 44, 0.05);
  padding: 11px 16px;
  color: #2c242c;
  font-size: 13px;
  font-weight: 700;
  line-height: 15px;
  display: -ms-flexbox;
  display: flex;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }
  .button-module__button___3GaBQ:hover {
    background-color: rgba(44, 36, 44, 0.1); }
  .button-module__button___3GaBQ div {
    margin-right: 11px; }

.button-module__danger___2zQ1A {
  background-color: rgba(255, 0, 0, 0.85);
  color: white; }
  .button-module__danger___2zQ1A:hover {
    background-color: rgba(255, 0, 0, 0.8); }

.button-module__save___2iG1M {
  background-color: #0076ff;
  color: white; }
  .button-module__save___2iG1M:hover {
    background-color: #1a84ff; }

.button-module__disabled___2c4xv {
  opacity: 0.2; }

.button-module__modal-button___3NepS {
  background-color: rgba(0, 118, 255, 0.95);
  margin: 0 auto;
  width: 75px;
  margin-top: 20px;
  color: white; }
  .button-module__modal-button___3NepS:hover {
    background-color: rgba(0, 118, 255, 0.9); }
  .button-module__modal-button___3NepS > div {
    margin-right: 6px;
    font-size: 13px; }

.button-module__inline___hq3Md {
  display: inline-block; }
