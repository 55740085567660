.attachment-module__attachments___q4ZbA {
  background-color: #ffffff;
  padding: 0px 20px 20px 20px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  padding: 20px; }

.attachment-module__headline___opZzY {
  color: #000000;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.2px;
  margin: 0; }

.attachment-module__current-file___3PK00 {
  color: #0076ff;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.16px;
  margin: 0;
  margin-bottom: 11px;
  word-break: break-word;
  text-align: center; }

.attachment-module__content___4K1fY {
  display: -ms-flexbox;
  display: flex;
  margin-top: 14px; }

.attachment-module__file-container___R_3bP {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center; }

.attachment-module__file-picker___25t3h {
  border-radius: 4px;
  border: 1px dashed #0076ff;
  background-color: #fafcfe;
  width: 297px;
  height: 281px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  cursor: pointer; }
  .attachment-module__file-picker___25t3h:hover {
    background-color: #f2f7fc; }

.attachment-module__file-picker-content___3UUZU {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center; }

.attachment-module__file-picker-headline___3UL1R {
  color: #0076ff;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.16px;
  margin: 0;
  margin-top: 11px; }

.attachment-module__file-picker-description___fqvo1 {
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 0.16px;
  margin: 0;
  margin-top: 8px; }
  .attachment-module__file-picker-description___fqvo1 b {
    font-weight: 700;
    letter-spacing: 0.16px; }

.attachment-module__text___hxAIJ {
  margin-left: 20px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex: 1;
      flex: 1; }

.attachment-module__label___1lz7l {
  color: #a9a9a9;
  font-size: 10px;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0; }

.attachment-module__textarea___3-M9z {
  margin-top: 15px;
  height: 100px;
  border-radius: 4px;
  border: 1px solid #d3dbe4;
  width: 100%;
  padding: 10px;
  color: black;
  font-size: 14px;
  font-weight: 400; }

.attachment-module__save-button___1dFNg {
  color: #0076ff;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.3px;
  width: 78px;
  height: 35px;
  border-radius: 4px;
  border: 1px solid #0076ff;
  -ms-flex-item-align: end;
      align-self: flex-end;
  margin-top: auto;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  cursor: pointer; }
  .attachment-module__save-button___1dFNg:hover {
    background: white; }

.attachment-module__close___2ypES {
  position: absolute;
  top: 20px;
  right: 20px; }

.attachment-module__image___3w36c {
  -o-object-fit: contain;
     object-fit: contain;
  width: 100%;
  height: 112px;
  margin-bottom: 10px;
  margin-top: -25px; }
