.layout-module__layout___16Shp {
  display: -ms-flexbox;
  display: flex;
  min-height: 100vh; }
  @media (max-width: 900px) {
    .layout-module__layout___16Shp {
      -ms-flex-direction: column;
          flex-direction: column; } }

.layout-module__logo___5koBM {
  position: absolute;
  top: 50px;
  max-width: 670px;
  width: 90%; }

.layout-module__column-left___3Cke5 {
  width: 55%;
  background-color: #2c242c;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  position: relative; }
  @media (max-width: 900px) {
    .layout-module__column-left___3Cke5 {
      width: 100%;
      padding-bottom: 60px;
      padding-top: 100px; } }

.layout-module__column-right___ASlzP {
  width: 45%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  box-shadow: 2px 0 14px #221b22;
  border-radius: 4px; }
  @media (max-width: 900px) {
    .layout-module__column-right___ASlzP {
      width: 100%;
      padding-bottom: 60px;
      padding-top: 60px; } }
