.headline-module__container___1xqtb {
  -ms-flex-align: center;
      align-items: center;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 15px; }

.headline-module__state-circle___xnX9- {
  border: 1px solid #E49D0B;
  border-radius: 50%;
  height: 8px;
  margin-right: 7px;
  transition: 100ms all;
  width: 8px; }

.headline-module__state-circle___xnX9-.headline-module__active___3My0e {
  background-color: #7de40b;
  border: 1px solid #7de40b; }

.headline-module__text___1P6DC {
  color: #2c242c;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 2px;
  margin-right: 7px; }

.headline-module__icon___1YEwY {
  cursor: pointer;
  color: #cecece;
  font-size: 12px;
  font-weight: 400; }

.headline-module__tooltip___3TK4u {
  -ms-flex-align: center;
      align-items: center;
  display: -ms-flexbox !important;
  display: flex !important; }
