.items-module__item___1m_aa {
  position: relative;
  background: rgba(255, 255, 255, 0.5);
  -ms-flex-pack: start;
      justify-content: flex-start; }

.items-module__order___31poV {
  position: absolute;
  left: -16px;
  color: #a9a9a9;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.26px; }

.items-module__input___3OfGl {
  width: 100%;
  color: black;
  font-size: 14px;
  font-weight: 400;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-appearance: none;
  outline: none;
  border: none;
  margin-left: 20px;
  height: 40px;
  border: 1px solid transparent;
  padding-left: 10px;
  padding-right: 10px;
  background: transparent;
  -ms-flex: 1;
      flex: 1;
  margin-right: 60px; }

.items-module__input-hover___yQBIC {
  border-radius: 4px;
  border: 1px solid #d3dbe4;
  color: #6f6f6f; }
  .items-module__input-hover___yQBIC:focus {
    color: black;
    border: 1px solid #0076ff; }

.items-module__dragger___I7h91 {
  cursor: pointer; }

.items-module__wrapper___2xZ4X {
  width: 20px;
  height: 40px;
  left: -7px;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center; }

.items-module__border___21Ws1 {
  border-top: 1px solid #e8edf3;
  border-right: 1px solid #e8edf3;
  margin-bottom: -1px;
  margin-right: -1px; }

.items-module__border-bottom___teBlf {
  border-bottom: 1px solid #e8edf3; }

.items-module__icon___1ZMYu {
  position: absolute;
  right: 20px; }

.items-module__option___1dJyO {
  background-color: transparent;
  color: black;
  cursor: default;
  display: block;
  font-size: 14px;
  padding: 8px 12px;
  width: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  box-sizing: border-box;
  font-weight: 400; }
  .items-module__option___1dJyO:hover {
    background-color: #0076ff;
    color: white;
    cursor: pointer; }
